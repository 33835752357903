import { useContext, useEffect, useState, useCallback } from 'react'
import { Radio } from 'antd'
import styled from 'styled-components'
import JobBoardStakingPanel from '../JobBoardStakingPanel'
import SDCMeter from '../SDCMeter'
import { JobBoardContext, SDCJobsProps, ISDCJob } from '../../contexts/JobBoardContext'
import SDCJob from '../SDCJob'
import { initGemFarm } from '../../common/gem-farm';
import { initGemBank } from '../../common/gem-bank';
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react"
import { SignerWalletAdapter } from '@solana/wallet-adapter-base'
import { PublicKey } from '@solana/web3.js'

const JobBoardContent = styled.div`
text-align: left;
`
const JobBoardContainer = styled.div`
    
`

const JobBoardTitle = styled.div`
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 32px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    color: #30AAD1;
    background: -webkit-linear-gradient(45deg, #30AAD1, #EB8FB3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const JobBoardSubtitle = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-top: 95px;
    margin-bottom: 28px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    color: #30AAD1;  
`

const EntryLevelJobBoardSubtitle = styled(JobBoardSubtitle)`
    position: relative;
`

const MeterContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
`
const Description = styled.div`
    text-align: left;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    color: black;
    opacity: 0.75;
`

const JobBoardDescription = styled(Description)`
    margin-top: 78px;
    text-align: center;
    width: 500px;
`

const BonusButton = styled.div<{ color: string }>`
    display: flex;
    justify-content: center;
    margin-left: 16px;
    width: 98px;
    height: 37px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    background: ${props => props.color};
    border-radius: 99px;
    color: white;
`
const FilterButtonsWrapper = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    margin-left: auto;
    display: flex;
    flex-flow: row wrap;
`
const FilterTitle = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    margin-right: 9px;
    /* identical to box height, or 133% */

    text-align: center;

    color: #000000;

    opacity: 0.75;
`

const FilterRadioGroup = styled(Radio.Group)`
    .ant-radio-button-wrapper:first-child {
        border: none;
    }
    .ant-radio-button-wrapper:not(:first-child)::before {
        background: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        background: none;
        color: #6311FF;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
        border: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
        border: none;
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
        box-shadow: none;
    }
`

const FilterButton = styled(Radio.Button)`
    background: none;
    border: none;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    letter-spacing: -0.02em;

    color: #C4C4C4;

`

interface JobBoardInterface {
    jobBoardStakingIsVisible: boolean;
    showJobBoardStaking: () => void;
}

const SDCJobBoard = ({ jobBoardStakingIsVisible, showJobBoardStaking }: JobBoardInterface) => {

    const { entryLevelJobs, workingSniffJobs, biteCollarJobs, topDogsJobs, specialtyDogsJobs, explorerJobs, iconDogsJobs } = useContext<SDCJobsProps>(JobBoardContext);

    const [gf, setGf] = useState<any>(null);
    const [gb, setGb] = useState<any>(null);
    const [stakingPanelFarmId, setStakingPanelFarmId] = useState<PublicKey | null>(null);
    const [stakingPanelVaultId, setStakingPanelVaultId] = useState<PublicKey | null>(null);
    const [farmLockupPeriod, setFarmLockupPeriod] = useState<string | null>(null);
    const [farmStakedTotals, setFarmStakedTotals] = useState({});
    const [farmStakeDeadline, setFarmStakeDeadline] = useState(Number.MAX_SAFE_INTEGER);
    const [farmFull, setFarmFull] = useState(false);

    const [filterValue, setFilterValue] = useState("active");
    const [filteredJobs, setFilteredJobs] = useState({
        entryLevelJobs: entryLevelJobs.filter(j => !j.ended),
        workingSniffJobs: workingSniffJobs.filter(j => !j.ended),
        biteCollarJobs: biteCollarJobs.filter(j => !j.ended),
        topDogsJobs: topDogsJobs.filter(j => !j.ended),
        specialtyDogsJobs: specialtyDogsJobs.filter(j => !j.ended),
        explorerJobs: explorerJobs.filter(j => !j.ended),
        iconDogsJobs: iconDogsJobs.filter(j => !j.ended),
    });

    const wallet = useAnchorWallet();
    const connection = useConnection().connection;

    const onFilterChange = (e: any) => {
        setFilterValue(e.target.value);
    };

    const openStakingPanelForId = (farmId: string, vaultId: string, lockupPeriod: string, stakeDeadline: number, farmFull: boolean) => {
        setStakingPanelFarmId(new PublicKey(farmId));
        setStakingPanelVaultId(new PublicKey(vaultId));
        setFarmLockupPeriod(lockupPeriod);
        setFarmStakeDeadline(stakeDeadline);
        setFarmFull(farmFull);
        showJobBoardStaking();
    };

    const setStakedValue = (farmId: string, total: number) => {
        setFarmStakedTotals((prevState) => {
            return { ...prevState, ...{ [farmId]: total } };
        });
    }

    const getTotalStakedRatio = () => {
        let total: number = 0;

        for (const [key, value] of Object.entries(farmStakedTotals)) {
            total += value as number;
        }

        const ratio = total * 100 / 9999;

        return parseFloat((ratio).toFixed(2));
    };

    const initGemFarms = useCallback(() => {
        (async () => {
            if (wallet && connection) {
                const gemFarm = await initGemFarm(connection, wallet as SignerWalletAdapter);
                setGf(gemFarm);
            }
        })();
    }, [wallet, connection]);
    const initGemBanks = useCallback(() => {
        (async () => {
            if (wallet && connection) {
                const gemBank = await initGemBank(connection, wallet as SignerWalletAdapter);
                setGb(gemBank);
            }
        })();
    }, [wallet, connection]);

    useEffect(() => {
        initGemFarms();
        initGemBanks();
    }, [
        wallet,
        connection,
        initGemFarms,
        initGemBanks
    ]);

    useEffect(() => {
        if (filterValue === "ended") {
            setFilteredJobs({
                entryLevelJobs: entryLevelJobs.filter(j => j.ended),
                workingSniffJobs: workingSniffJobs.filter(j => j.ended),
                biteCollarJobs: biteCollarJobs.filter(j => j.ended),
                topDogsJobs: topDogsJobs.filter(j => j.ended),
                specialtyDogsJobs: specialtyDogsJobs.filter(j => j.ended),
                explorerJobs: explorerJobs.filter(j => j.ended),
                iconDogsJobs: iconDogsJobs.filter(j => j.ended),
            });
        }
        else {
            setFilteredJobs({
                entryLevelJobs: entryLevelJobs.filter(j => !j.ended),
                workingSniffJobs: workingSniffJobs.filter(j => !j.ended),
                biteCollarJobs: biteCollarJobs.filter(j => !j.ended),
                topDogsJobs: topDogsJobs.filter(j => !j.ended),
                specialtyDogsJobs: specialtyDogsJobs.filter(j => !j.ended),
                explorerJobs: explorerJobs.filter(j => !j.ended),
                iconDogsJobs: iconDogsJobs.filter(j => !j.ended),
            });
        }
    }, [
        filterValue,
    ]);

    return (
        <JobBoardContent>
            {jobBoardStakingIsVisible && stakingPanelFarmId && stakingPanelVaultId ?
                <JobBoardStakingPanel gf={gf} gb={gb} farmId={stakingPanelFarmId} vaultId={stakingPanelVaultId} lockupPeriod={farmLockupPeriod ?? "None"} stakingDeadline={farmStakeDeadline} jobFull={farmFull} />
                :
                <JobBoardContainer>
                    <JobBoardTitle>Job Board</JobBoardTitle>
                    {!wallet && <JobBoardDescription>{"Please connect your wallet"}</JobBoardDescription>}
                    {wallet && <>
                        <MeterContainer>
                            <SDCMeter meterValue={getTotalStakedRatio()} />
                            <JobBoardDescription><strong>Earn $BONES</strong>&#160;{`by sending your Soul Dogs to work in available jobs!`}
                            </JobBoardDescription>
                        </MeterContainer>
                        <EntryLevelJobBoardSubtitle>
                            {filteredJobs.entryLevelJobs.length !== 0 && `Entry Level`}
                            <FilterButtonsWrapper>
                                <FilterTitle>{`Filter:`}</FilterTitle>
                                <FilterRadioGroup onChange={onFilterChange} value={filterValue}>
                                    <FilterButton value="active">Active</FilterButton>
                                    <FilterButton value="ended">Closed</FilterButton>
                                    {/* <FilterButton value="myjobs">My Jobs</FilterButton> */}
                                </FilterRadioGroup>
                            </FilterButtonsWrapper>
                        </EntryLevelJobBoardSubtitle>
                        {entryLevelJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.entryLevelJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.workingSniffJobs.length !== 0 && <JobBoardSubtitle>
                            {`Working Sniff`}
                            <BonusButton color={"#EC00D4"}>1.25x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {workingSniffJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.workingSniffJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.biteCollarJobs.length !== 0 && <JobBoardSubtitle>
                            {`Bite Collar`}
                            <BonusButton color={"#00A5EC"}>1.5x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {biteCollarJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.biteCollarJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.topDogsJobs.length !== 0 && <JobBoardSubtitle>
                            {`Top Dogs`}
                            <BonusButton color={"#EC0055"}>3x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {topDogsJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.topDogsJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.specialtyDogsJobs.length !== 0 && <JobBoardSubtitle>
                            {`Specialty`}
                            <BonusButton color={"#33677C"}>5x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {specialtyDogsJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.specialtyDogsJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.explorerJobs.length !== 0 && <JobBoardSubtitle>
                            {`Explorers`}
                            <BonusButton color={"#CE5749"}>7.5x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {explorerJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.explorerJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}

                        {filteredJobs.iconDogsJobs.length !== 0 && <JobBoardSubtitle>
                            {`ICONs`}
                            <BonusButton color={"#6311FF"}>10x Bonus</BonusButton>
                        </JobBoardSubtitle>}
                        {iconDogsJobs.map((item: ISDCJob, index: number) => (
                            (!item.launchTime || Date.now() > item.launchTime) && <SDCJob key={index.toString()} filtered={!filteredJobs.iconDogsJobs.includes(item)} item={item} showJobBoardStaking={openStakingPanelForId} setFarmTotal={setStakedValue} gf={gf} />
                        ))}
                    </>}
                </JobBoardContainer>
            }
        </JobBoardContent>
    )
}

export default SDCJobBoard