import styled from 'styled-components'
import { useState, useReducer } from 'react'
import { MenuInterface } from './SDCMenu.interface'
import { Drawer, Row, Col } from 'antd'
import SDCRent from '../SDCRent'
import SDCButton from '../SDCButton'
import SDCJobBoard from '../SDCJobBoard'
import SDCOwnership from '../SDCOwnership'
import Nav from '../Nav'
import JobBoardProvider from '../../contexts/JobBoardContext'
import RentalProvider from '../../contexts/RentalContext'

const styles = {
    drawStyle: {
        background: '#FEF9F1',
        paddingLeft: '30px',
        paddingTop: '12px',
        paddingRight: '10px'
    },
}

const MenuContent = styled(Row)`
    text-align: left;
    position: relative;
    display:flex;
    flex-flow: column;
`

const RentContent = styled.div`
    text-align: left;
`

const Logo = styled.img`
    width: 250px;
    height: 150px;
    margin-top: -90px;
    margin-left: -40px;
`

const Title = styled.div`
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 32px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    color: #30AAD1;
    background: -webkit-linear-gradient(45deg, #30AAD1, #EB8FB3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Subtitle = styled.div`
    align-items: center;
    display: flex;
    flex-flow: row;
    width: 100%;
    margin-top: 95px;
    margin-bottom: 28px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 28px;
    line-height: 30px;
    color: #30AAD1;  
`

const Description = styled.div`
    text-align: left;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    color: black;
    opacity: 0.75;
`

const LinkButton = styled.div`
    position: relative;
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #18A0FB;
    height: 52.5px;
    width: 100%;

    background-blend-mode: soft-light, normal;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 11.25px;
    border: none;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: 400;
    font-size: 27px;
    line-height: 52.5px;
    text-align: center;
    letter-spacing: 3%;
    color: #eeebeb;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    padding-top : 5px;
    opacity: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #18A0FB;
    &:hover {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #18A0FB;
        color: #eeebeb;
    }
    &:active, &:focus {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #18A0FB;
        color: #eeebeb;
    }
`

const ButtonGroup = styled(Row)`
    margin-top: 85px;
`

const Text = styled.div`
    margin-top: 14px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 107% */

    letter-spacing: -0.02em;

    color: #506274;
`

const halfWidth = (global.window.innerWidth) / 2

// const useForceUpdate = () => {
//     const [value, setValue] = useState(0);
//     return () => setValue(value => value + 1);
// }

const SDCMenu = ({ onClose, visible }: MenuInterface) => {
    // const forceUpdate = useForceUpdate();
    const [menuContentVisible, setMenuContentVisible] = useState(true)
    const [ownershipContentVisible, setOwnershipContentVisible] = useState(false)
    const [jobBoardContentVisible, setJobBoardContentVisible] = useState(false)
    const [rentBoardContentVisible, setRentContentVisible] = useState(false)
    const [jobBoardStakingIsVisible, setJobBoardStakingIsVisible] = useState(false);
    const [rentStakingIsVisible, setRentStakingIsVisible] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const backgroundPurple = {
        normal: 'linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF',
        hover: '#7438f5'
    };

    const backgroundDisable = {
        normal: 'linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #939099',
        hover: '#939099'
    };

    const backgroundBlue = {
        normal: 'linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #18A0FB',
        hover: '#18A0FB'
    }

    const showMenuContent = () => {
        if (jobBoardContentVisible && jobBoardStakingIsVisible) {
            setJobBoardStakingIsVisible(false)
        } else if (rentBoardContentVisible && rentStakingIsVisible) {
            setRentStakingIsVisible(false)
        } else {
            setMenuContentVisible(true)
            setOwnershipContentVisible(false)
            setJobBoardContentVisible(false)
            setRentContentVisible(false)
            setJobBoardStakingIsVisible(false)
        }
    }

    const showOwnershipContent = () => {
        setOwnershipContentVisible(true)
        setMenuContentVisible(false)
        setJobBoardContentVisible(false)
        setRentContentVisible(false)
        setJobBoardStakingIsVisible(false)
        setRentStakingIsVisible(false)
    }

    const showJobBoardContent = () => {
        setJobBoardContentVisible(true)
        setMenuContentVisible(false)
        setOwnershipContentVisible(false)
        setRentContentVisible(false)
        setJobBoardStakingIsVisible(false)
        setRentStakingIsVisible(false)
    }

    const showRentContent = () => {
        setRentContentVisible(true)
        setMenuContentVisible(false)
        setJobBoardContentVisible(false)
        setOwnershipContentVisible(false)
        setJobBoardStakingIsVisible(false)
        setRentStakingIsVisible(false)
    }

    const showJobBoardStaking = () => {
        setJobBoardStakingIsVisible(true)
    }

    const showRentStaking = () => {
        setRentStakingIsVisible(true)
    }

    return (
        <Drawer width={halfWidth} mask={false} closable={false} drawerStyle={styles.drawStyle} placement="right" onClose={onClose} visible={visible}>
            <Nav onClose={onClose} menuContentVisible={menuContentVisible} showMenuContent={showMenuContent} forceUpdate={forceUpdate} />
            {menuContentVisible && <MenuContent>
                <Logo src="/logo.png"></Logo>
                <Title>
                    Welcome to soul dogs City
                </Title>
                <Description><strong>Own, earn, and explore</strong>&#160;{`in the first virtual city on Solana◎`}</Description>
                <ButtonGroup gutter={[12, 23.5]}>
                    <Col span={8}>
                        <SDCButton title="Ownership" comingsoon={false} background={backgroundPurple} onClick={showOwnershipContent} />
                    </Col>
                    <Col span={8}>
                        {/* <SDCButton title="Job Board" comingsoon={true} background={backgroundDisable} onClick={() => ""}/> */}
                        <SDCButton title="Job Board" comingsoon={false} background={backgroundPurple} onClick={showJobBoardContent} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="Rent" comingsoon={false} background={backgroundPurple} onClick={showRentContent}  />
                    </Col>
                    {/* Second Row */}
                    <Col span={8}>
                        <SDCButton title="$BONES" comingsoon={false} background={backgroundPurple} onClick={() => { window.open("https://raydium.io/swap/?from=11111111111111111111111111111111&to=bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE", "_blank"); }} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="LP" comingsoon={false} background={backgroundPurple} onClick={() => { window.open("https://raydium.io/liquidity/add/?coin0=bonegFPgrpZ4bfVn3kQK1aMbGYddWtfMAywNt5LsuVE&coin1=sol&fixed=coin0&ammId=3fPw9qTp2U5qPo8F4UYL1kfAet1AeMmXKicLHSDBaBqy", "_blank"); }} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="Farm" comingsoon={false} background={backgroundPurple} onClick={() => { window.open("https://raydium.io/farms/", "_blank"); }} />
                    </Col>
                    {/* Third Row */}
                    <Col span={8}>
                        <SDCButton title="Shop" comingsoon={true} background={backgroundDisable} onClick={() => ""} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="Dice" comingsoon={false} background={backgroundPurple} onClick={() => { window.open("https://roll.souldogs.city", "_blank"); }} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="Lottery" comingsoon={true} background={backgroundDisable} onClick={() => ""} />
                    </Col>
                    <Col span={8}>
                        <SDCButton title="City Council" comingsoon={true} background={backgroundDisable} onClick={() => ""} />
                    </Col>

                    <Col span={24}>
                        <Text>Looking for something else?</Text>
                    </Col>

                    {/* Last Row */}
                    <Col span={8}>
                        <LinkButton><a style={{ color: 'white' }} href={"https://magiceden.io/marketplace/soul_dogs_mintpass"} target="_blank" rel="noreferrer">Mintpass</a></LinkButton>
                    </Col>
                    <Col span={8}>
                        <LinkButton><a style={{ color: 'white' }} href={"https://magiceden.io/marketplace/soul_dogs"} target="_blank" rel="noreferrer">Soul Dogs</a></LinkButton>
                    </Col>
                    <Col span={8}>
                        <LinkButton><a style={{ color: 'white' }} href={"https://www.souldogs.city/#roadmap"} target="_blank" rel="noreferrer">Roadmap</a></LinkButton>
                    </Col>
                    <Col span={8}>
                        <LinkButton><a style={{ color: 'white' }} href={"https://discord.gg/s8S6g29yuX"} target="_blank" rel="noreferrer">Help</a></LinkButton>
                    </Col>
                </ButtonGroup>
            </MenuContent>
            }
            {ownershipContentVisible && <SDCOwnership />}
            {jobBoardContentVisible && (
                <JobBoardProvider>
                    <SDCJobBoard jobBoardStakingIsVisible={jobBoardStakingIsVisible} showJobBoardStaking={showJobBoardStaking} />
                </JobBoardProvider>
            )}
            {rentBoardContentVisible && (
                <RentalProvider>
                    <RentContent>
                        <SDCRent rentStakingIsVisible={rentStakingIsVisible} showRentStaking={showRentStaking}/>
                    </RentContent>
                </RentalProvider>
            )}
        </Drawer>
    )
}

export default SDCMenu

