import { useState } from 'react';
import { Slider } from 'antd';
import styled from 'styled-components'

const SDCSliderWrapper = styled.div`
    display:flex;
    justify-content: center;
    padding-right: 10%;    
    padding-left: 10%;    
    flex-flow: column;
    width: 100%;
`

const SDCRentMeterTitle = styled.div`
    text-align: center;
    margin-right: 10%;
    margin-bottom: 5px;
    margin-left: auto;
`

const SDCRentMeterNumber = styled.div`
    @font-face {
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-weight: 680;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height, or 100% */

    text-transform: uppercase;

    /* Blue */

    color: #3172FF;
`

const SDCRentMeterDesc = styled.div`
    background: rgba(229, 229, 229, 0.4);
    border: 0.5px solid #F09EC4;
    border-radius: 4px;
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-size: 14px;
    line-height: 14px;
    padding: 4px 8px;
    /* identical to box height, or 100% */

    text-transform: uppercase;

    /* Blue */

    color: #3172FF;
`

const SDCSlider = styled(Slider)`
`

const MarkWrapperFifty = styled.div`
    margin-top: 18px;
    margin-left: 10px;
`

const MarkWrapperSeventyFive = styled(MarkWrapperFifty)`
`

const MarkWrapperNinty = styled(MarkWrapperFifty)`
`

const MarkTitle = styled.div`
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-weight: 660;
    font-size: 20px;
    line-height: 24px;
`

const MarkMiletone1 = styled(MarkTitle)`
    color: #3172FF;
`

const MarkMiletone2 = styled(MarkTitle)`
    color: #3172FF;
`

const MarkMiletone3 = styled(MarkTitle)`
    color: #3172FF;
`

const MarkDesc = styled.div`
    font-family: 'Obviously550';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    margin-top: 4px;
    color: rgba(10, 10, 10, 0.6);
`


const marks = {
    75: {
        // style: {
        //     left: '75%',
        //     width: '75px'
        // },
        label: <MarkWrapperSeventyFive>
            <MarkMiletone2>75%</MarkMiletone2>
            <MarkDesc>Merch Unlock</MarkDesc>
        </MarkWrapperSeventyFive>
    },
    95: {
        // style: {
        //     left: '90%'
        // },
        label: <MarkWrapperNinty>
            <MarkMiletone3>95%</MarkMiletone3>
            <MarkDesc>Airdrop Unlock</MarkDesc>
        </MarkWrapperNinty>
    }
}

const styles = {
    track: {
        width: '100%',
        height: '16px',
        borderRadius: '99px',
        background: '-webkit-linear-gradient(left, #18A0FB, #6311FF, #EB8FB3, #D13032)',
    },
    handle: {
        // width: '21px',
        // height: '43px',
        top: '10px',
        // borderRadius: '50% 50% 75% 75%',
        // background: 'linear-gradient(268.3deg, rgba(0, 0, 0, 0.15) -31.5%, rgba(0, 0, 0, 0.121109) 46.03%, rgba(0, 0, 0, 0.0525) 56.96%, rgba(0, 0, 0, 0) 114.74%), #FF7426',
        // backgroundBlendMode: 'hard-light, normal',
        // boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.14)',
        // border: 'none'
    }
}

interface SDCRentMeterInterface {
    meterValue: number;
}

const SDCRentMeter = ({ meterValue }: SDCRentMeterInterface) => {

    return (
        <SDCSliderWrapper>
            <SDCRentMeterTitle>
                <SDCRentMeterNumber>
                 
                </SDCRentMeterNumber>
                <SDCRentMeterDesc>
                {`${meterValue}% `} RENTED!
                </SDCRentMeterDesc>
            </SDCRentMeterTitle>
            <SDCSlider defaultValue={meterValue} tooltipVisible={false} disabled={true} value={meterValue} trackStyle={styles.track} handleStyle={styles.handle} marks={marks} />
        </SDCSliderWrapper>
    )
}

export default SDCRentMeter;