import { useState, createContext } from 'react';
import { SPECIAL_EDITION_TOKENS } from '../../global';

export interface ISDCRentals {
    farmId: string;
    img: string,
    title: string,
    subTitle?: string,
    ended?: boolean,    // is the farm already closed
    onChainwhitelistCriteria?: (metadata: any) => boolean,     // whitelist creiteria that are based on on-chain metadata like CM ids
    externalMetadataCriteria?: (metadata: any) => boolean,    // whitelist creiteria that are based on off-chain metadata like traits
    launchTime?: number,    // launch time of the farm on frontend in unix epoch milliseconds
}

type Props = {
    children: JSX.Element
}

export type SDCRentalsProps = {
    studentHousing: ISDCRentals[],
    coolCrib: ISDCRentals[],
    poshPad: ISDCRentals[],
    topDigs: ISDCRentals[],
    dynomite: ISDCRentals[],
    specialty: ISDCRentals[],
}

export const RentalContext = createContext<SDCRentalsProps>({
    studentHousing: [],
    coolCrib: [],
    poshPad: [],
    topDigs: [],
    dynomite: [],
    specialty: [],
});

const RentalProvider = ({ children }: Props) => {
    const [studentHousing, setStudentHousing] = useState<ISDCRentals[] | []>([
        {
            farmId: "FkHEyZ4r4pru1sr8VV1CBWzrdUVAesnadwAP9d3ZW4R9",
            img: "./rental/common.png",
            title: 'Doggie Dorms',
        },
    ])

    const [coolCrib, setCoolCrib] = useState<ISDCRentals[] | []>(
        [
            {
                farmId: "HKQq7NLGB3uXZP54HfawcsVp4Ucn5SK2aSKC1jSWr3sG",
                img: "./rental/brick.png",
                title: "Bricks & Bones",
                subTitle: "(Cool only)",
                externalMetadataCriteria: (metadata) => {
                    let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                    return rarity === "Cool";
                },
            },
        ]
    )

    const [poshPad, setPoshPad] = useState<ISDCRentals[] | []>([
        {
            farmId: "GLDAgDYx4uDqEK4TShWgnd5SonaC7AVy6p4jCVgYzoD1",
            img: "./rental/bungalow.png",
            title: "Bones Bungalow",
            subTitle: "(Groovy only)",
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                return rarity === "Groovy";
            }
        },
    ])

    const [topDigs, setTopDigs] = useState<ISDCRentals[] | []>([
        {
            farmId: "8GKAeDYmHvk53HydrKbcVMGbD4k4KC7Pp57ZNtZxXuAY",
            img: "./rental/penhouse.png",
            title: "Puppy Penthouse",
            subTitle: "(Fly only)",
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                return rarity === "Fly";
            }
        },
    ])

    const [dynomite, setDynomite] = useState<ISDCRentals[] | []>([
        {
            farmId: "ACyjXfA8wkasCqcFgu6JCHwfxPisQ6suStNFi4A1ufkg",
            img: "./rental/dynomite.png",
            title: 'Pooch Palace',
            subTitle: '(Dynomite only)',
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                return rarity === "Dynomite";
            },
        }
    ])

    const [specialty, setSpecialty] = useState<ISDCRentals[] | []>([
        {
            farmId: "Atq87ZGCv9dY5cgjRXijW4rAMwQv14siAAoiNJBqyRkE",
            img: "./rental/icon.png",
            title: 'SDC Landmark',
            subTitle: '(1/1s only)',
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Neighborhood").value;
                return rarity.includes("Icon Village");
            },
        },
    ])

    return (
        <RentalContext.Provider value={{ studentHousing, coolCrib, poshPad, topDigs, dynomite, specialty }}>
            {children}
        </RentalContext.Provider>
    )
}

export default RentalProvider;