import { useState, useEffect, useCallback, useContext } from 'react'
import styled from 'styled-components'
import { Button, Checkbox, Row, Col, Spin, Modal } from 'antd';
import SDCStakeCard from '../SDCStakeCard';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import Countdown, { CountdownRenderProps } from 'react-countdown'
import { PublicKey } from '@solana/web3.js'
import {
    getNFTsByOwner,
    getNFTMetadataForMany,
} from '../../common/web3/NFTget'
import { NETWORK, SD_CM_ID, errorMessage } from '../../global'
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react"
import { BN } from '@project-serum/anchor';
import { stringifyPKsAndBNs, findFarmerPDA } from '../../sdk'
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import { JobBoardContext, SDCJobsProps, ISDCJob } from '../../contexts/JobBoardContext'
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

const StakingPanelContainer = styled(Row)`
    display: flex;
    flex-flow: row wrap;
    margin-top: 75px;
    height: 630px;
`
const AvailableDogsContainer = styled(Col)`
`

const CheckboxGroup = styled(Checkbox.Group)`
    width: 100%;
`

const AvailableDogsRow = styled(Row)`
`

const ActionButtons = styled(Col)`
    margin-left: 20px;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-flow: column wrap;
`

const ActionButton = styled(Button)`
    width: 132px;
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
    height: 36px;
    margin-bottom: 8px;
    background-blend-mode: soft-light, normal;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 11.25px;
    border: none;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20.25px;
    text-align: center;
    letter-spacing: 3%;
    color: #eeebeb;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    &:hover {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #7438f5;
        color: #eeebeb;
    }
    &:focus {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
        color: #eeebeb;   
    }
    &:active {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
        color: #eeebeb;
    }
    &:disabled {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #939099;
        color: #eeebeb;
        opacity: 0.3;
        &:hover {
            background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #939099;
            color: #eeebeb;
            opacity: 0.3; 
        }
    }
`

const Title = styled.div`
    width: 100%;
    margin-bottom: 18px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #00A6EB;
`

const StakedDogsContainer = styled(Col)`
`

const StakedDogsRow = styled(Row)`

`

const StartStakingContainer = styled.div`
    align-items: center;
    padding: 20px;
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    background: #E8E8E8;
    border-radius: 4px;
    text-align: center;
`

const StartStakingText = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    color: #000000;

    opacity: 0.75;
`

const StakeButton = styled(ActionButton)`
    margin-top: 26px;
    margin-bottom: 40px;
`

const UnstakeButton = styled(StakeButton)`
`

const CancelButton = styled(ActionButton)`
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #A4A4A4;
    &:hover {
        background: #A4A4A4;
        color: white;
    }
`

const TimeToLockTimerStats = styled.div`
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #18A0FB;
`


const TimerText = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    /* or 171% */

    text-align: center;

    /* Gray Lighter */

    color: #222222;
`

const StakingModalContainer = styled(Modal)`
    .ant-modal-header {
        background-color: #FEF9F1;
        border-radius: 20px;
    }

    .ant-modal-title {
        font-family: Luckiest Guy;
        font-style: normal;
        font-weight: normal;
        padding-top: 18px;
        font-size: 30px;
        line-height: 30px;
        /* identical to box height, or 125% */

        letter-spacing: -0.02em;

        color: #00A6EB;
    }
    .ant-modal-content {
        background-color: #FEF9F1;
        border-radius: 20px;
    }

    .ant-modal-body {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
        /* or 220% */

        text-align: left;
        // letter-spacing: 0.1em;

        color: #40667A;
        padding-bottom: 10px;
    }
`
const CheckAllContainer = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px;
`

export interface StakingPanelInterface {
    gf: any;
    gb: any;
    farmId: PublicKey;
    vaultId: PublicKey;
    lockupPeriod: string;
    stakingDeadline: number;
    jobFull: boolean;
}

const isTimeExpired = (endTime: number, refreshSeed?: number) => {
    return Date.now() > endTime
}

const JobBoardStakingPanel = ({ gf, gb, farmId, vaultId, lockupPeriod, stakingDeadline, jobFull }: StakingPanelInterface) => {
    const [bank, setBank] = useState<PublicKey | null>(null);
    const [vaultLocked, setVaultLocked] = useState<boolean>(false);
    const [farmerState, setFarmerState] = useState<string | null>(null);
    const [farmerLockupExpiring, setFarmerLockupExpiring] = useState<number>(0);
    const [currentWalletNFTs, setCurrentWalletNFTs] = useState<any[]>([]);
    const [currentVaultNFTs, setCurrentVaultNFTs] = useState<any[]>([]);
    const [selectedWalletNFTs, setSelectedWalletNFTs] = useState<any[]>([]);
    const [selectedVaultNFTs, setSelectedVaultNFTs] = useState<any[]>([]);
    const [refreshSeed, setRefreshSeed] = useState(0);
    const [indeterminateAvailable, setIndeterminateAvailable] = useState(false);
    const [checkAllAvailable, setCheckAllAvailable] = useState(false);
    const [indeterminateWorkforce, setIndeterminateWorkforce] = useState(false);
    const [checkAllWorkforce, setCheckAllWorkforce] = useState(false);


    // UI states
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLoadingWalletNFTs, setIsLoadingWalletNFTs] = useState(false);
    const [isLoadingVaultNFTs, setIsLoadingVaultNFTs] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const onAvailableDogsSelect = (value: any) => {
        setSelectedWalletNFTs(value);
        setIndeterminateAvailable(!!value.length && value.length < currentWalletNFTs.length);
        setCheckAllAvailable(value.length === currentWalletNFTs.length);
    }

    const onStakedDogsSelect = (value: any) => {
        setSelectedVaultNFTs(value)
        setIndeterminateWorkforce(!!value.length && value.length < currentVaultNFTs.length);
        setCheckAllWorkforce(value.length === currentVaultNFTs.length);
    }

    const handleOk = () => {
        setIsModalVisible(false);
        beginStaking();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showConfirmModal = () => {
        setIsModalVisible(true);
    }

    // --------------------------------------- web3
    const wallet = useAnchorWallet();
    const connection = useConnection().connection;
    const { entryLevelJobs, workingSniffJobs, biteCollarJobs, topDogsJobs, specialtyDogsJobs, explorerJobs, iconDogsJobs } = useContext<SDCJobsProps>(JobBoardContext);
    const allFarms = [...entryLevelJobs, ...workingSniffJobs, ...biteCollarJobs, ...topDogsJobs, ...specialtyDogsJobs, ...explorerJobs, ...iconDogsJobs];

    const populateWalletNFTs = useCallback(async () => {
        if (!wallet || !connection) {
            return;
        }

        const farmConfig = allFarms.find(f => f.farmId === farmId.toBase58());

        setIsLoadingWalletNFTs(true);

        const fetchedNFTs = await getNFTsByOwner(
            wallet.publicKey!,
            connection,
            (metadata) => {
                let network = NETWORK;

                if (network === "mainnet-beta") {
                    let whitelistFunc = farmConfig?.whitelistCriteria;
                    // default to only show soul dogs
                    if (!whitelistFunc) {
                        whitelistFunc = (metadata) => { return metadata.data.creators[0].address === SD_CM_ID; };
                    }

                    return whitelistFunc(metadata);
                }
                else {
                    return true;
                }
            }
        );

        const externalFilter = farmConfig?.externalMetadataCriteria;

        if (externalFilter) {
            setCurrentWalletNFTs(fetchedNFTs.filter(nft => externalFilter(nft.externalMetadata)));
        }
        else {
            setCurrentWalletNFTs(fetchedNFTs);
        }

        setIsLoadingWalletNFTs(false);
    }, [wallet, connection]);

    const populateVaultNFTs = useCallback(async () => {
        if (!wallet || !connection) {
            return;
        }

        setIsLoadingVaultNFTs(true);

        const foundGDRs = await gb.fetchAllGdrPDAs(vaultId);
        if (foundGDRs && foundGDRs.length) {
            console.log(`found a total of ${foundGDRs.length} gdrs`);

            const mints = foundGDRs.map((gdr: any) => {
                return { mint: gdr.account.gemMint };
            });

            const fetchedNFTs = await getNFTMetadataForMany(
                mints,
                connection
            );
            console.log(
                `populated a total of ${fetchedNFTs.length} vault NFTs`
            );
            setCurrentVaultNFTs(fetchedNFTs);
        }
        else {
            setCurrentVaultNFTs([]);
        }

        setIsLoadingVaultNFTs(false);
    }, [connection, wallet, gb, vaultId]);

    const fetchFarmer = useCallback(async () => {
        const [farmerPDA] = await findFarmerPDA(
            farmId,
            wallet!.publicKey
        );

        const fetchedFarmerAcc = await gf.fetchFarmerAcc(farmerPDA);
        console.log("farmer state " + gf.parseFarmerState(fetchedFarmerAcc));
        setFarmerState(gf.parseFarmerState(fetchedFarmerAcc));
        setFarmerLockupExpiring(fetchedFarmerAcc?.minStakingEndsTs?.toNumber() * 1000);

        console.log(
            `farmer found at ${wallet!.publicKey?.toBase58()}:`,
            stringifyPKsAndBNs(fetchedFarmerAcc)
        );
    }, [farmId, gf, wallet]);

    const updateVaultState = useCallback(async () => {
        try {
            const vAcc = await gb.fetchVaultAcc(vaultId);
            setBank(vAcc.bank);
            setVaultLocked(vAcc.locked);
        }
        catch (e) {
            console.error(`exception when update vault state: ${e}`);
        }
    }, [gb, vaultId]);

    const init = useCallback(() => {
        (async () => {
            if (wallet && connection) {
                //prep vault + bank variables
                await updateVaultState();

                //fetch farmer state
                await fetchFarmer();

                //populate wallet + vault nfts
                await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
            }
        })();
    }, [wallet, connection, fetchFarmer, populateVaultNFTs, populateWalletNFTs, updateVaultState]);

    // --------------------------------------- actions

    const beginStaking = async () => {
        if (isTimeExpired(stakingDeadline)) {
            enqueueSnackbar(`Can't stake after passing application deadline`, {
                variant: "error",
            });
            return;
        }

        if (jobFull) {
            enqueueSnackbar(`Can't stake when the job is full`, {
                variant: "error",
            });
            return;
        }

        setIsProcessing(true);
        try {
            let { txSig } = await gf.stakeWallet(farmId);
            await connection.confirmTransaction(txSig);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await fetchFarmer();
            enqueueSnackbar(`Dogs staked!`, {
                variant: "success",
            });
        }
        catch (e) {
            console.error(`exception when staking: ${e}`);
            enqueueSnackbar(`Unable to stake: ${errorMessage(e)}`, {
                variant: "error",
            });
        }
        setIsProcessing(false);
    };

    const endStaking = async () => {
        setIsProcessing(true);
        try {
            let { txSig } = await gf.unstakeWallet(farmId);
            await connection.confirmTransaction(txSig);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await fetchFarmer();
            enqueueSnackbar(`Dogs unstaked!`, {
                variant: "success",
            });
        }
        catch (e) {
            console.error(`exception when unstaking: ${e}`);
            enqueueSnackbar(`Unable to unstake: ${errorMessage(e)}`, {
                variant: "error",
            });
        }
        setIsProcessing(false);
    };

    const depositGems = async () => {
        if (!selectedWalletNFTs || selectedWalletNFTs.length === 0) {
            return;
        }

        if (isTimeExpired(stakingDeadline)) {
            enqueueSnackbar(`Application deadline has passed!`, {
                variant: "error",
            });
            return;
        }

        if (jobFull) {
            enqueueSnackbar(`This job is full!`, {
                variant: "error",
            });
            return;
        }

        let signatures = [];
        setIsProcessing(true);
        let exception = false;

        for (const nft of selectedWalletNFTs) {
            console.log(nft);
            const creator = new PublicKey(
                //todo currently simply taking the 1st creator
                (nft.onchainMetadata as any).data.creators[0].address
            );
            console.log('creator is', creator.toBase58());
            try {
                const { txSig } = await gb.depositGemWallet(
                    bank,
                    vaultId,
                    new BN(1),
                    nft.mint,
                    nft.pubkey!,
                    creator
                );
                signatures.push(txSig);
                console.log('deposit done', txSig);
            }
            catch (e) {
                exception = true;
                console.error(`exception when depositing: ${e}`);
                enqueueSnackbar(`Unable to add dogs: ${errorMessage(e)}`, {
                    variant: "error",
                });
            }
        }

        const result = await Promise.all(
            signatures.map((s) => connection.confirmTransaction(s))
        );

        const failedTx = result.filter((r) => r.value.err != null);

        if (failedTx.length > 0) {
            console.error(`Transactions failed: ${failedTx}`);
            enqueueSnackbar(`Transactions failed: ${failedTx}`, {
                variant: "error",
            });
        } else if (!exception) {
            console.log('All transactions succeeded:', signatures);
            enqueueSnackbar(`Dogs added to workforce!`, {
                variant: "success",
            });
        }

        setSelectedVaultNFTs([]);
        setSelectedWalletNFTs([]);
        setIndeterminateAvailable(false);
        setIndeterminateWorkforce(false);
        setCheckAllAvailable(false);
        setCheckAllWorkforce(false);

        await new Promise(resolve => setTimeout(resolve, 1000));
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
        setIsProcessing(false);
    };

    const withdrawGems = async () => {
        if (!selectedVaultNFTs || selectedVaultNFTs.length === 0) {
            return;
        }

        let signatures = [];
        setIsProcessing(true);
        let exception = false;

        for (const nft of selectedVaultNFTs) {
            try {
                const { txSig } = await gb.withdrawGemWallet(
                    bank,
                    vaultId,
                    new BN(1),
                    nft.mint
                );
                signatures.push(txSig);
                console.log('withdrawal done', txSig);
            }
            catch (e) {
                exception = true;
                console.error(`exception when withdrawing: ${e}`);
                enqueueSnackbar(`Unable to remove dogs: ${errorMessage(e)}`, {
                    variant: "error",
                });
            }
        }

        const result = await Promise.all(
            signatures.map((s) => connection.confirmTransaction(s))
        );

        const failedTx = result.filter((r) => r.value.err != null);

        if (failedTx.length > 0) {
            console.error(`Transactions failed: ${failedTx}`);
            enqueueSnackbar(`Transactions failed: ${failedTx}`, {
                variant: "error",
            });
        } else if (!exception) {
            console.log('All transactions succeeded:', signatures);
            enqueueSnackbar(`Dogs removed from workforce`, {
                variant: "success",
            });
        }

        setSelectedVaultNFTs([]);
        setSelectedWalletNFTs([]);
        setIndeterminateAvailable(false);
        setIndeterminateWorkforce(false);
        setCheckAllAvailable(false);
        setCheckAllWorkforce(false);

        await new Promise(resolve => setTimeout(resolve, 1000));
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
        setIsProcessing(false);
    };

    const onCheckAllAvailableChange = (e: CheckboxChangeEvent) => {
        setSelectedWalletNFTs(e.target.checked ? currentWalletNFTs : []);
        setIndeterminateAvailable(false);
        setCheckAllAvailable(e.target.checked);
    };

    const onCheckAllWorkforceChange = (e: CheckboxChangeEvent) => {
        setSelectedVaultNFTs(e.target.checked ? currentVaultNFTs : []);
        setIndeterminateWorkforce(false);
        setCheckAllWorkforce(e.target.checked);
    };

    useEffect(init, [
        wallet,
        connection,
        init
    ]);

    return (
        <StakingPanelContainer>
            <StakingModalContainer title="Are you sure?"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                footer={[
                    <ActionButton key="submit" type="primary" onClick={handleOk}>
                        Stake
                    </ActionButton>,
                    <CancelButton key="back" onClick={handleCancel}>
                        Cancel
                    </CancelButton>,
                ]}>
                <p>You're staking in a job that has a lock-up period. You will <strong>NOT</strong> be able to remove or add any dogs to this job during the lock-up period.</p>
            </StakingModalContainer>
            <AvailableDogsContainer span={8}>
                <Title>Available</Title>
                {isLoadingWalletNFTs && <Spin size="large" />}
                {currentWalletNFTs && currentWalletNFTs.length > 0 &&
                    <CheckAllContainer>
                        <Checkbox indeterminate={indeterminateAvailable} disabled={farmerState !== 'unstaked'} onChange={onCheckAllAvailableChange} checked={checkAllAvailable}>
                            Select All Available Dogs
                        </Checkbox>
                    </CheckAllContainer>}
                <CheckboxGroup onChange={onAvailableDogsSelect} value={selectedWalletNFTs} disabled={farmerState !== 'unstaked'}>
                    <AvailableDogsRow>
                        {currentWalletNFTs.map((item, index) => (
                            <Col span={8}><SDCStakeCard key={index} item={item} /></Col>
                        ))}
                    </AvailableDogsRow>
                </CheckboxGroup>
                {/* </Radio.Group> */}

            </AvailableDogsContainer>
            <ActionButtons span={5}>
                <ActionButton disabled={isProcessing || farmerState !== 'unstaked' || selectedWalletNFTs === null || selectedWalletNFTs.length === 0} onClick={depositGems}>
                    {isProcessing ? <CircularProgress size={20} /> : <>Add <ArrowRightOutlined /></>}
                </ActionButton>
                <ActionButton disabled={isProcessing || farmerState !== 'unstaked' || selectedVaultNFTs === null || selectedVaultNFTs.length === 0} onClick={withdrawGems}>
                    {isProcessing ? <CircularProgress size={20} /> : <><ArrowLeftOutlined /> Remove</>}
                </ActionButton>
            </ActionButtons>
            <StakedDogsContainer span={8}>
                <Title>Workforce</Title>
                {isLoadingVaultNFTs && <Spin size="large" />}
                {currentVaultNFTs && currentVaultNFTs.length > 0 &&
                    <CheckAllContainer>
                        <Checkbox indeterminate={indeterminateWorkforce} disabled={farmerState !== 'unstaked'} onChange={onCheckAllWorkforceChange} checked={checkAllWorkforce}>Select All Workforce Dogs
                        </Checkbox>
                    </CheckAllContainer>}
                <Checkbox.Group onChange={onStakedDogsSelect} value={selectedVaultNFTs} disabled={farmerState !== 'unstaked'}>
                    <StakedDogsRow>
                        {currentVaultNFTs.map((item, index) => (
                            <Col span={8}><SDCStakeCard key={index} item={item} /></Col>
                        ))}
                    </StakedDogsRow>
                </Checkbox.Group>
                {currentVaultNFTs.length !== 0 && <StartStakingContainer>
                    {farmerState === 'staked' && !isTimeExpired(farmerLockupExpiring, refreshSeed) &&
                        <TimeToLockTimerStats>
                            <StartStakingText>Time to Unlock</StartStakingText>
                            <Countdown autoStart={true} date={farmerLockupExpiring} renderer={({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
                                if (completed) {
                                    setRefreshSeed(Math.random());
                                    // Render a completed state
                                    return ""
                                } else {
                                    // Render a countdown
                                    return <TimerText>{`${days}D ${hours}H ${minutes}M ${seconds}`}</TimerText>;
                                }
                            }} />
                        </TimeToLockTimerStats>
                    }
                    {farmerState !== 'staked' && <StartStakingText>
                        {isTimeExpired(stakingDeadline) ? `Job application deadline has passed!`
                            : jobFull ? `This job is full!`
                                : `Don’t forget to start staking the dogs in this job!`}
                    </StartStakingText>}

                    {farmerState === 'unstaked' &&
                        <StakeButton disabled={isProcessing || !currentVaultNFTs || currentVaultNFTs.length === 0 || isTimeExpired(stakingDeadline) || jobFull}
                            onClick={lockupPeriod && lockupPeriod !== "None" ? showConfirmModal : beginStaking}>
                            {isProcessing ? <CircularProgress size={20} /> : `Stake All`}
                        </StakeButton>}
                    {farmerState === 'staked' &&
                        <UnstakeButton disabled={isProcessing || !isTimeExpired(farmerLockupExpiring, refreshSeed)} onClick={endStaking}>
                            {isProcessing ? <CircularProgress size={20} /> : `Unstake All`}
                        </UnstakeButton>}
                    {!(farmerState === 'staked') && !isTimeExpired(stakingDeadline) && !jobFull && <StartStakingText>
                        {lockupPeriod && lockupPeriod !== "None" && <>
                            Your dogs will be locked up for <strong>{lockupPeriod}</strong>. Please keep in mind you <strong>CANNOT</strong> remove or add any dogs to this job during the lock up period!</>
                        }
                    </StartStakingText>
                    }
                </StartStakingContainer>
                }
            </StakedDogsContainer>
        </StakingPanelContainer>
    )
}

export default JobBoardStakingPanel
