import { useEffect, useState, useMemo } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import SDCMenu from './components/SDCMenu';
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import { Button, Row } from 'antd'
import styles from "./App.module.css"
import styled from 'styled-components'
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
    getLedgerWallet,
    getPhantomWallet,
    getSlopeWallet,
    getSolflareWallet,
    getSolletExtensionWallet,
    getSolletWallet,
    getTorusWallet,
} from '@solana/wallet-adapter-wallets';
import {
    WalletModalProvider,
} from '@solana/wallet-adapter-react-ui';
import { NETWORK, RPC_HOST } from './global'
import { SnackbarProvider } from "notistack";
// @ts-ignore
import Protect from "react-app-protect";

const unityContext = new UnityContext({
    loaderUrl: "Build/Build.loader.js",
    dataUrl: "Build/Build.data",
    frameworkUrl: "Build/Build.framework.js",
    codeUrl: "Build/Build.wasm",
    companyName: "SoulDogs",
    productName: "SoulDogsCity",
    productVersion: "0.1",
});

const MenuButton = styled(Button)`
    position: absolute;
    right: 28px;
    top: 47px;
    z-index: 10;
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
    width: 110px;
    height: 40px;
    background-blend-mode: soft-light, normal;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 11.25px;
    border: none;

    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.03em;
    color: white;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    padding-top: 5px;
    &:hover {
        background: #7438f5;
        color: white;
    }
    &:active, &:focus {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;;
        color: white;
    }
`

const Canvas = styled(Row)`
    position: relative;
`;

const WaterMarkContainer = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    z-index: 2;
    width: fit-content;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    color: #F9F9F9;
    background: grey;
    padding: 0 20px;
    opacity: 0.75;
    cursor: none;
    @media only screen and (max-width: 768px) {
        display: none;
    }
`

const MenuContainer = styled.div`
    @media only screen and (max-width: 768px) {
        display: none;
    } 
`

const UnityContainer = styled.div`
    height: 100vh;
    width: 100%;
    @media only screen and (max-width: 768px) {
        display: none;
    }   
`

const MobileVersionLandingScreen = styled.div`
    /* background-image: url('loading-screen.gif') */
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 30px;
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 100vh;
    z-index: 5;
    @media only screen and (min-width: 769px) {
        display: none;
    }

`

function App() {

    const [NFTId, setNFTId] = useState("");
    const [initialized, setInitialized] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const showMenu = () => {
        setMenuVisible(true)
    }

    const closeMenu = () => {
        setMenuVisible(false)
    }

    useEffect(() => {
        unityContext.on("HouseClicked", (id: string) => {
            setNFTId(id);
            // alert("NFT id:" + id);
        });
    }, []);

    useEffect(() => {
        unityContext.on("SceneInitialized", () => {
            setInitialized(true);
        });
    }, []);

    // Can be set to 'devnet', 'testnet', or 'mainnet-beta'
    const network = NETWORK as WalletAdapterNetwork;

    // You can also provide a custom RPC endpoint
    const endpoint = RPC_HOST;

    // @solana/wallet-adapter-wallets includes all the adapters but supports tree shaking --
    // Only the wallets you configure here will be compiled into your application
    const wallets = useMemo(() => [
        getPhantomWallet(),
        getSlopeWallet(),
        getSolflareWallet(),
        getTorusWallet({
            options: { clientId: 'Get a client ID @ https://developer.tor.us' }
        }),
        getLedgerWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
    ], [network]);

    return (
        <>
            {/* <Protect sha512='78AEE4713F92F92410DC9E027B8CC3E0E4BA87EE4E1263726F70BE152DDE212FDC7E237F40619925F3C769ABF73829B8ADE03AFB69BE6A1EA73F7AEF6F655D1F'
                    blur={true}
                    boxTitle = {`Enter your password`}
                    styles={{
                        header: { fontSize: '20px', marginBottom: '10px', marginTop: '10px'},
                        input: { marginBottom: '10px'}
            }}> */}
                <Canvas justify='center'>
                    <MobileVersionLandingScreen>
                    Soul Dogs City v1 is currently not supported on mobile web. Please access the site on desktop. RUFF RUFF!
                    </MobileVersionLandingScreen>
                    {/* {initialized && ( */}
                    <MenuContainer>
                        <WaterMarkContainer>
                            Under Construction - Demo
                        </WaterMarkContainer>
                        <LoadingScreen enabled={!initialized} />
                        <MenuButton onClick={showMenu}>Menu</MenuButton>
                            <SnackbarProvider maxSnack={5} autoHideDuration={5000} anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}>

                                <ConnectionProvider endpoint={endpoint}>
                                    <WalletProvider wallets={wallets} autoConnect>
                                        <WalletModalProvider>
                                            <SDCMenu onClose={closeMenu} visible={menuVisible} />
                                        </WalletModalProvider>
                                    </WalletProvider>
                                </ConnectionProvider>
                            </SnackbarProvider>
                            <UnityContainer>
                                <Unity
                                    unityContext={unityContext}
                                    tabIndex={1}
                                    className={styles.unity}
                                    devicePixelRatio={1}
                                />
                            </UnityContainer>
                        </MenuContainer>
                    {/* )} */}
                    {/* <Player enabled={initialized} url={"background_audio.mp3"}/> */}
                </Canvas>
            {/* </Protect> */}
        </>
    );
}

export default App;
