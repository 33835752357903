import { useState } from 'react';
import { Slider } from 'antd';
import styled from 'styled-components'

const SDCSliderWrapper = styled.div`
    display:flex;
    justify-content: center;    
    flex-flow: column;
    width: 500px;
    /* .ant-slider-dot:nth-child(1){
        left: 25% !important;
    }

    .ant-slider-dot:nth-child(2){
        left: 50% !important;
    }

    .ant-slider-dot:nth-child(3){
        left: 75% !important;
    } */
`

const SDCMeterTitle = styled.div`
    text-align: center;
    margin-right: 10%;
    margin-bottom: 5px;
    margin-left: auto;
`

const SDCMeterNumber = styled.div`
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    line-height: 30px;
    /* identical to box height, or 71% */

    letter-spacing: -0.02em;
    color: #FF7426;
`

const SDCMeterDesc = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #000000;
    opacity: 0.75;
`

const SDCSlider = styled(Slider)`
`

const MarkWrapperFifty = styled.div`
    margin-top: 18px;
    margin-left: 10px;
`

const MarkWrapperSeventyFive = styled(MarkWrapperFifty)`
`

const MarkWrapperNinty = styled(MarkWrapperFifty)`
`

const MarkTitle = styled.div`
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: -0.02em;
`

const MarkMiletone1 = styled(MarkTitle)`
    color: #288DFF;
`

const MarkMiletone2 = styled(MarkTitle)`
    color: #0737FE;
`

const MarkMiletone3 = styled(MarkTitle)`
    color: #9715E4;
`

const MarkDesc = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    color: #000000;
    opacity: 0.75;
`


const marks = {
    50: {
        style: {
            left: '50%',
            width: '75px'
        },
        label: <MarkWrapperFifty>
            <MarkMiletone1>50%</MarkMiletone1>
            <MarkDesc>Liquidity Boost</MarkDesc>
        </MarkWrapperFifty>
    },
    75: {
        style: {
            left: '75%',
            width: '75px'
        },
        label: <MarkWrapperSeventyFive>
            <MarkMiletone2>75%</MarkMiletone2>
            <MarkDesc>Airdrop Unlock</MarkDesc>
        </MarkWrapperSeventyFive>
    },
    90: {
        style: {
            left: '90%'
        },
        label: <MarkWrapperNinty>
            <MarkMiletone3>90%</MarkMiletone3>
            <MarkDesc>New Roadmap Reveal</MarkDesc>
        </MarkWrapperNinty>
    }
}

const styles = {
    track: {
        width: '498px',
        height: '16px',
        borderRadius: '99px',
        background: '-webkit-linear-gradient(right, #EC00D4, #0038FF, #46CEFE)',
    },
    handle: {
        width: '21px',
        height: '43px',
        top: '-10px',
        borderRadius: '50% 50% 75% 75%',
        background: 'linear-gradient(268.3deg, rgba(0, 0, 0, 0.15) -31.5%, rgba(0, 0, 0, 0.121109) 46.03%, rgba(0, 0, 0, 0.0525) 56.96%, rgba(0, 0, 0, 0) 114.74%), #FF7426',
        backgroundBlendMode: 'hard-light, normal',
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.14)',
        border: 'none'
    }
}

interface SDCMeterInterface {
    meterValue: number;
}

const SDCMeter = ({ meterValue }: SDCMeterInterface) => {

    return (
        <SDCSliderWrapper>
            <SDCMeterTitle>
                <SDCMeterNumber>
                    {`${meterValue}% `}
                </SDCMeterNumber>
                <SDCMeterDesc>
                    of dogs at work!
                </SDCMeterDesc>
            </SDCMeterTitle>
            <SDCSlider defaultValue={meterValue} tooltipVisible={false} disabled={true} value={meterValue} trackStyle={styles.track} handleStyle={styles.handle} marks={marks} />
        </SDCSliderWrapper>
    )
}

export default SDCMeter;