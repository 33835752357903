import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Spin } from 'antd'
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react"
import {
    getNFTsByOwner,
} from '../../common/web3/NFTget';
import { SDC_UPGRADE_AUTHORITY } from '../../global'


const OwnershipContent = styled.div`
text-align: left;
`
const Title = styled.div`
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 32px;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    color: #30AAD1;
    background: -webkit-linear-gradient(45deg, #30AAD1, #EB8FB3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const Description = styled.div`
    text-align: left;
    width: 100%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 30px;
    color: black;
    opacity: 0.75;
`

const NFTWrapper = styled(Col)`
    position: relative;
    display: flex;
`

const NFTImage = styled.img`
    width: 95%;
    height: 100%;
    z-index: 2;
`
const NFTShadow = styled.div`
    width: 90%;
    height: 100%;
    position: absolute;
    left: 20px;
    top: 10px;
    background: rgba(0, 0, 0, 0.1);
`
const NFTList = styled(Row)`
    margin-top: 71px;
`

const SDCOwnership = () => {

    const [currentWalletNFTs, setCurrentWalletNFTs] = useState<any[] | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const wallet = useAnchorWallet();
    const connection = useConnection().connection;

    const populateWalletNFTs = () => {
        (async () => {
            if (!wallet || !connection) {
                return;
            }
            setIsLoading(true);
            try {
                const walletNFTs = await getNFTsByOwner(
                    wallet.publicKey!,
                    connection,
                    (metadata) => {
                        return metadata.updateAuthority === SDC_UPGRADE_AUTHORITY;
                    }
                );
                // only show soul dogs related NFTs
                setCurrentWalletNFTs(walletNFTs);
            }
            catch (e) {
                console.error(`exception when getting nft data: ${e}`);
            }
            setIsLoading(false);
        })();
    };

    useEffect(populateWalletNFTs, [
        wallet,
        connection,
    ]);

    return (
        <OwnershipContent>
            <Title>Ownership</Title>
            <Description>{wallet ? "View all your Soul Dogs City NFTs in one place" : "Please connect your wallet"}</Description>
            {isLoading && <Spin size="large" />}
            {wallet && currentWalletNFTs &&
                (<NFTList gutter={[18, 28]}>
                    {currentWalletNFTs.map((item) => (
                        <NFTWrapper span={8}>
                            <NFTImage src={item.externalMetadata.image} alt="" />
                            <NFTShadow />
                        </NFTWrapper>
                    ))}
                </NFTList>)}
        </OwnershipContent>
    )
}

export default SDCOwnership