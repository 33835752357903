// export const NETWORK = "devnet";
// export const RPC_HOST = "https://api.devnet.solana.com/"
export const NETWORK = "mainnet-beta";
export const RPC_HOST = "https://polished-warmhearted-bridge.solana-mainnet.quiknode.pro/21e38436d7bb27103c2dde34b3b55c6ce79d1fb2/"
export const SDC_UPGRADE_AUTHORITY = 'L2eH9GHbMySGF9HKJ3QnJ3mRZSv5iFRP92TpcQdniCj';
export const SD_CM_ID = '8CWKUvr9KDqFZww2q3xurag3FdjqmAUEVT97hX3ZBYz9';
export const DOGHOUSE_CM_ID = '91Lk3vR4jtqmmFzeUUMgFK9tbEMPW14QrvWNDhMWNwW3';
export const TOKEN_DECIMALS = 2;
export const SPECIAL_EDITION_TOKENS = [
    "5CykARhnKa3QF14WbmJ4jQGSyBs6rig9rfa3yBTqpGDr",
    "Gn8d8rK6eS8A22Vp7EVEEHq8aBZjZzsdepHPXGeBZruo",
    "DRbMdVyzsy8euLj9j5QdSUiAK79Neaf4THGpkqkNjqpa",
    "AipacMczwuKknXBT3NYBhAYBL4tAfK77WopMhb32xGZ9",
    "EH1hHAv7ApPU6YK48g1221RwKDtrpKpgDDTou9dMVhB8",
    "5g87EnZ8h48iQdwkiLXu1PD5vTYbgwAARCyh4sdwDaG3",
    "2rVR2269iT5qAUBtHfK9GUB9DanMB4eGFrYhPxtF7Z1k",
    "93eVqZta9kYCBis95e1R7ywqaNQgonj2Cct3yarTtUWK",
    "6Nzom96uU1DSkQtoqJm9r1dKGdQkmnDWz1pVXAeaX3C",
    "7xSLpXvUMApmuxQ64GkScMtrrri6h6uDsERQNk2Nv8jx",
    "6Y4LCQ8KUcBLzc83L8GpRbgz6CK4iSFahKXqzYajw3uX",
    "9ezbbexyQzmdq7tqosDRNzunXp8iXZHQfLGut9HNApom",
    "7unNxCqB9mcbo54rfV7gQsR2Mv2wknobeUfeGgeCour7",
    "8nweQJzPhG84jXKP4ro6tzNqMCLTTkpAGHGjDuYdao6s",
    "FdFmK9F1ShqiggawkKkwnFGLvqpMAvozhPVrhCn7DAH6",
    "C7M3RWyTvBjYDCBpVvLBkeJhT6WWykTLkUAvGjdxZ3Db",
    "3A1ka9YWJ3NrKc1Mc6VQSynCLMg7VKVBJ3kvy8hS8CXp",
    "AcWhmpXB7XdZzQii7DsNy671suAzZjnBVshuU7PTz7bj",
    "HyQLWMLMoiYdfvQAYtoBghDLSvUtuV2s2dnbQ9k6NaqZ",
    "56vNUCF3KhhtbVZfZ9zXdsZfUXeteijjN4XuuoZfQqoU",
    "6iVrvTdh9wiRZE4z2q8MxmxkZNMELPXopU5jSw3m62sQ",
    "6akrspivrw86MRDGrEfnKa3j2ph3xGUoKVdSVueDP9Yt",
    "6HhRgktg9GjQMJCAuvbMcD6bwhz1L6L8m5nzAQCaBmVg",
    "BDxhdoR7T1MKYGgeEp8faxyFdjK3k54W1bqik9jBZYg",
    "HJ2BRXV4Eo1zXJsHKpQTfTKboEYMs3QYEavj6w8iNtmh"
];

const ErrorCodes = {
    // 'program error: 0x1': 'Insufficient funds in wallet',
    'no record of a prior credit': 'Insufficient funds in wallet',
    '0x17a1': 'Too many dogs staked exceeding available positions',
    '0x179D': 'Not enough tokens in reward pool',
    '0x179A': 'Lock up period is not passed yet',
    '0x1785': 'Workforce is empty',
    '0x1786': 'NFT not whitelisted',
    '0x1770': 'Arithmetic Error',
};

export const errorMessage = (e: any) => {
    for (const [key, value] of Object.entries(ErrorCodes)) {
        if (e.message.includes(key)) {
            return value;
        }
    }

    return e.message;
}
