import { useState, createContext } from 'react';
import { SD_CM_ID, SPECIAL_EDITION_TOKENS } from '../../global';

export interface ISDCJob {
    farmId: string;
    img: string,
    title: string,
    subTitle?: string,
    ended?: boolean,
    whitelistCriteria?: (metadata: any) => boolean,
    externalMetadataCriteria?: (metadata: any) => boolean,
    launchTime?: number,
}

type Props = {
    children: JSX.Element
}

export type SDCJobsProps = {
    entryLevelJobs: ISDCJob[],
    workingSniffJobs: ISDCJob[],
    biteCollarJobs: ISDCJob[],
    topDogsJobs: ISDCJob[],
    specialtyDogsJobs: ISDCJob[],
    explorerJobs: ISDCJob[],
    iconDogsJobs: ISDCJob[],
}

export const JobBoardContext = createContext<SDCJobsProps>({
    entryLevelJobs: [],
    workingSniffJobs: [],
    biteCollarJobs: [],
    topDogsJobs: [],
    specialtyDogsJobs: [],
    explorerJobs: [],
    iconDogsJobs: [],
});

const JobBoardProvider = ({ children }: Props) => {
    const [entryLevelJobs, setEntryLevelJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "8AZcVXrWQGnooBw8c2E4uh93jLu1g7rEdEKeGULmfTmv",
            img: "/jobs/1.jpeg",
            title: 'DoggieDAO Intern',
        },
    ])

    const [workingSniffJobs, setWorkingSniffJobs] = useState<ISDCJob[] | []>(
        [
            {
                farmId: "3vMPj13emX9JmifYcWc77ekEzV1F37ga36E1YeSr6Mdj",
                img: "/jobs/2.jpeg",
                title: 'Soul Detective',
                ended: true,
            },
            {
                farmId: "Jq39c76E2NJ88XsdTtUAB2yRLxL6t5ak68oeYRWLWHs",
                img: "/jobs/daredevil.jpg",
                title: 'Doggie Daredevil',
                ended: true,
            },
            {
                farmId: "FxmwYnjnunnoT18Vi29cUhJ2CaWsMS7zgu6bGRczap5s",
                img: "/jobs/trainer.jpg",
                title: 'Soul Pup Trainer',
                ended: true,
            },
            {
                farmId: "6LxxFWBHfmCnyovvCA4u5Z5BNsxcZefNG8nUMdajnfvJ",
                img: "/jobs/cowboy.jpg",
                title: "Soul Cowboy",
                subTitle: "(Cool only)",
                externalMetadataCriteria: (metadata) => {
                    let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                    return rarity === "Cool";
                },
                launchTime: 1649649600000
            },
        ]
    )

    const [biteCollarJobs, setBiteCollarJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "3He6tHSDVZqxfUfGHcJGQ9a7ySrqdBAfrJFF3aoSupBP",
            img: "/jobs/3.jpeg",
            title: 'Barkeologist',
            ended: true,
        },
        {
            farmId: "F26dFNuEPnd5BVRJuTeMWtDFnqQxa1qAC5z7ziY5Qgwg",
            img: "/jobs/dogtor.jpg",
            title: 'Dogtor',
            ended: true,
        },
        {
            farmId: "EH5Ls7c4E7RyQFyHq4Haz8Lh3kJAfPgrEByGR1jjDwAh",
            img: "/jobs/master.jpg",
            title: 'Jui Shih Tzu Master',
            ended: true,
        },
        {
            farmId: "ASUTpxrbCbAU9GxQGT8ThzH4xHb3JFMedhNzhiyG2pF9",
            img: "/jobs/trader.jpg",
            title: "Bones Trader",
            subTitle: "(Groovy only)",
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                return rarity === "Groovy";
            },
            launchTime: 1649649600000
        },
    ])

    const [topDogsJobs, setTopDogsJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "GqgqHyqj5qpg6qf6podSag2h5YwXSiFHeEoABcuor6Pn",
            img: "/jobs/architect.jpg",
            title: 'City Architect',
            ended: true,
        },
        {
            farmId: "3xhZzLTjUAxo7SXAi1Q69evr8nKRzWUD8Qa7MSSan6Tb",
            img: "/jobs/mc.jpg",
            title: 'Soul MC',
            ended: true,
        },
        {
            farmId: "3fGiXw2oMmt3fKD8kyKtCbVZf4cGsghXiu9PiwNGsrPj",
            img: "/jobs/4.jpeg",
            title: 'Reservoir Dog',
            ended: true,
        },
        {
            farmId: "F7krx8NpQfgBfSTpnTHqTdFG5HbLR7C2cPY7hbNqZEv2",
            img: "/jobs/rockstar.jpg",
            title: "SDC Rockstar",
            subTitle: "(Fly only)",
            externalMetadataCriteria: (metadata) => {
                let rarity = metadata.attributes.find((attr: any) => attr.trait_type === "Soul-O-Meter").value;
                return rarity === "Fly";
            },
            launchTime: 1649649600000
        },
    ])

    const [specialtyDogsJobs, setSpecialtyDogsJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "5YQhCyw8NKvyvk94d9LMiHHQweiwn1j1Xhcnbyeivtjm",
            img: "/jobs/5.jpeg",
            title: 'Trendsetter',
            subTitle: '(Dynomite only)',
            externalMetadataCriteria: (metadata) => {
                let rarity = "Common";

                metadata.attributes.forEach((item: any) => {
                    if (item.trait_type === "Soul-O-Meter") {
                        rarity = item.value;
                    }
                });

                if (rarity === "Dynomite") {
                    return true;
                }
                else {
                    return false;
                }
            }
        },
        {
            farmId: "2q7x2jc8BHW7NYhKE7RMkhFcaiD2uPqfvjuXbuSEAtER",
            img: "/jobs/alien.jpg",
            title: 'Area51 Test Subject',
            subTitle: '(Alien & Mutant only)',
            externalMetadataCriteria: (metadata) => {
                let breed = metadata.attributes.find((attr: any) => attr.trait_type === "Breed").value;
                return breed === "Alien" || breed === "Mutant";
            },
            ended: true,
        },
        {
            farmId: "DBiZuCvt9fPv56ANALqYJJj79MR5ox8tAj5kuzFbxyi3",
            img: "/jobs/grill.jpg",
            title: 'Grill Gangsta',
            subTitle: '(Grillz only)',
            externalMetadataCriteria: (metadata) => {
                let mouth = metadata.attributes.find((attr: any) => attr.trait_type === "Mouth").value;
                return mouth === "Chrome Grill" || mouth === "Rainbow Grill" || mouth === "Gold Grill";
            },
            launchTime: 1649649600000,
            ended: true,
        },
        {
            farmId: "9uKut3ujFuDb4sGHMHb5jUn9dRFB4HibZkyFKXrh8q1T",
            img: "/jobs/guitar.png",
            title: 'Soul Roadie',
            subTitle: '(Guitar picks only)',
            externalMetadataCriteria: (metadata) => {
                let mouth = metadata.attributes.find((attr: any) => attr.trait_type === "Neck").value;
                return mouth === "Swirl Guitar Pick Necklace" || mouth === "White Guitar Pick Necklace" || mouth === "Gold Guitar Pick Necklace";
            },
            ended: true,
            // launchTime: 1649649600000
        },
        {
            farmId: "2h1b7poEaZFkQAFG5xJYcz5rhRA8RZysdxpayGCprJH2",
            img: "/jobs/robo.jpeg",
            title: 'RoboPup',
            subTitle: '(Laser eyes, Terminator & Cyborg)',
            externalMetadataCriteria: (metadata) => {
                let mouth = metadata.attributes.find((attr: any) => attr.trait_type === "Eyes").value;
                return mouth === "Cyborg" || mouth === "Terminator" || mouth === "Laser Eyes";
            },
            ended: true,
            // launchTime: 1649649600000
        },
        {
            farmId: "2yEW14B3fTHGamcTcxk6sGCLHahpk6UqPtQdqp15yA7e",
            img: "/jobs/hang10.jpg",
            title: 'Hang10',
            subTitle: '(Hawaiian Shirts only)',
            externalMetadataCriteria: (metadata) => {
                let mouth = metadata.attributes.find((attr: any) => attr.trait_type === "Clothes").value;
                return mouth === "Blue Hawaiian Shirt" || mouth === "Red Hawaiian Shirt";
            },
            launchTime: 1653494400000,
            ended: true,
        },
        {
            farmId: "4tcgEsxPTb3xgaieUzhD9e7p8anHzDUiwTZ7dMfbXTdR",
            img: "/jobs/nude.jpg",
            title: 'Nudies',
            subTitle: '(No Clothes)',
            externalMetadataCriteria: (metadata) => {
                let mouth = metadata.attributes.find((attr: any) => attr.trait_type === "Clothes").value;
                return mouth === "None";
            },
            launchTime: 1653494400000,
            ended: true,
        },
    ])

    const [explorerJobs, setExplorerJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "BwKcdVgQ26pYVrbdZrcEVLkpkNHBWq8zAFYEwQ1doxMh",
            img: "/jobs/indiana.jpg",
            title: 'Indiana Bones',
            ended: true,
        },
        {
            farmId: "GZUsk5fLYP3XJMRB6vw2drPaMuxShXrqDqApFeDMiUuP",
            img: "/jobs/inigo.jpg",
            title: 'Inigo Dogtoya',
            ended: true,
        },
        {
            farmId: "7ong8kpC5hgiL7wuXVPrNLsTinuifFc4QqJonbjmrtPq",
            img: "/jobs/apollo.jpg",
            title: 'Apollo Breed',
            launchTime: 1649613600000,
            ended: true,
        },
        {
            farmId: "F8N7Gxe61zb632hKEZEmtjkvDTAAdVprBcTj2B1djGyN",
            img: "/jobs/long.jpg",
            title: 'Long Haul Doggo',
            launchTime: 1653494400000,
            ended: true,
        },
    ])

    const [iconDogsJobs, setIconDogsJobs] = useState<ISDCJob[] | []>([
        {
            farmId: "92QaufL2j8RT5xdyVKrsGgSt4uxEXpPrVujmzYm6VxrU",
            img: "/jobs/6.jpeg",
            title: 'Soul Legend',
            subTitle: '(1/1s only)',
            whitelistCriteria: (metadata) => {
                return metadata.data.creators[0].address === SD_CM_ID || SPECIAL_EDITION_TOKENS.includes(metadata.mint);
            },
            externalMetadataCriteria: (metadata) => {
                // only last 25 dogs in the collection are icons
                if (metadata.collection.name === "Soul Dogs") {
                    const nameSplit = metadata.name.split("#");
                    const NftId = Number(nameSplit[nameSplit.length - 1]);
                    if (NftId < 9975) {
                        return false;
                    }
                }

                return true;
            }
        },
    ])

    return (
        <JobBoardContext.Provider value={{ entryLevelJobs, workingSniffJobs, biteCollarJobs, topDogsJobs, specialtyDogsJobs, explorerJobs, iconDogsJobs }}>
            {children}
        </JobBoardContext.Provider>
    )
}

export default JobBoardProvider;