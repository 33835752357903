import Wallet from "../Wallet/Wallet"
import styled from 'styled-components'
import { Row, Button } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import Refresh from '@mui/icons-material/Refresh'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';

const Header = styled(Row)`

`

const CloseButton = styled.div`
    margin-left: 16px;
    &:hover {
        background: #F2F2F2;
        opacity: 0.5;
        cursor: pointer;
    }
    &:active {
        color: white;
    }
`

const Icons = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    font-size: 23.79px;
    color: #A4A4A4;
`

const TwitterIcon = styled.i`

`
const DiscordIcon = styled.i`
    color: #7289DA;

`
const BackIcon = styled.div`
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    color: #3172FF;
`

const BackButton = styled(Button)`
    font-family: 'ObviouslyBold';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #3172FF;
    background: #FFFFFF;
    border: 1px solid #3172FF;
    border-radius: 4px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
`

const BackButtonWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    margin-top: 42px;
    margin-bottom: 24px;
    .ant-btn:hover, .ant-btn:focus {
        border: 1px solid #3172FF;
        border-radius: 4px;
        background: rgba(49, 114, 255, 0.08);  
    }
`

const HeaderLeft = styled.div`
    width: 100%;
    display: flex;
    flew-flow: row;
`

const HeaderRight = styled.div`
    width: 100%;
    display: flex;
    flew-flow: row;
    justify-content: end;
`

const IconWrapper = styled.div`
    margin-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    gap: 12px;
    background: #F2F2F2;
    border: 1px solid #3172FF;
    border-radius: 4px;
    width: 40px;
    height: 40px;

`

const SVGWrapper = styled.svg`
    margin-right: 5.5px;
`

interface NavInterface {
    onClose: () => void;
    menuContentVisible: boolean;
    showMenuContent: () => void;
    forceUpdate: () => void;
}

const Nav = ({ onClose, menuContentVisible, showMenuContent, forceUpdate }: NavInterface) => {
    return (
        <Header justify="space-between">
            <HeaderRight>
                <Icons>
                    {/* <Tooltip title="Refresh">
                        <IconButton sx={{
                            marginRight: 1
                        }}>
                            <Refresh onClick={forceUpdate}/>
                        </IconButton>
                    </Tooltip> */}
                    <IconWrapper>
                        <a href="https://twitter.com/SoulDogsCity" target="_blank"><TwitterIcon className="fab fa-twitter" /></a>
                    </IconWrapper>
                    <IconWrapper>
                        <a href="https://discord.gg/souldogscity" target="_blank"><DiscordIcon className="fab fa-discord" /></a>
                    </IconWrapper>
                </Icons>
                <Wallet />
                <CloseButton onClick={onClose}><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 4C0 1.79086 1.79086 0 4 0H36C38.2091 0 40 1.79086 40 4V36C40 38.2091 38.2091 40 36 40H4C1.79086 40 0 38.2091 0 36V4Z" fill="#F2F2F2"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24.4711 15.5288C24.7314 15.7891 24.7314 16.2112 24.4711 16.4716L16.4711 24.4716C16.2107 24.7319 15.7886 24.7319 15.5283 24.4716C15.2679 24.2112 15.2679 23.7891 15.5283 23.5288L23.5283 15.5288C23.7886 15.2684 24.2107 15.2684 24.4711 15.5288Z" fill="#3172FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M15.5283 15.5288C15.7886 15.2684 16.2107 15.2684 16.4711 15.5288L24.4711 23.5288C24.7314 23.7891 24.7314 24.2112 24.4711 24.4716C24.2107 24.7319 23.7886 24.7319 23.5283 24.4716L15.5283 16.4716C15.2679 16.2112 15.2679 15.7891 15.5283 15.5288Z" fill="#3172FF"/>
                <path d="M4 1H36V-1H4V1ZM39 4V36H41V4H39ZM36 39H4V41H36V39ZM1 36V4H-1V36H1ZM4 39C2.34315 39 1 37.6569 1 36H-1C-1 38.7614 1.23858 41 4 41V39ZM39 36C39 37.6569 37.6569 39 36 39V41C38.7614 41 41 38.7614 41 36H39ZM36 1C37.6569 1 39 2.34315 39 4H41C41 1.23858 38.7614 -1 36 -1V1ZM4 -1C1.23858 -1 -1 1.23858 -1 4H1C1 2.34315 2.34315 1 4 1V-1Z" fill="#3172FF"/>
                </svg>
                </CloseButton>
            </HeaderRight>
            <HeaderLeft>
                {!menuContentVisible && <BackButtonWrapper onClick={showMenuContent}>
                    <BackButton type="link">
                    <SVGWrapper width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.60636 4.8438C6.33582 4.59406 5.91405 4.61093 5.66431 4.88147L3.51046 7.21478C3.27473 7.47015 3.27473 7.86378 3.51046 8.11915L5.6643 10.4525C5.91404 10.7231 6.33581 10.7399 6.60636 10.4902C6.87691 10.2405 6.89378 9.81869 6.64405 9.54815L5.52297 8.33364L12.0003 8.33364C12.3685 8.33364 12.667 8.03516 12.667 7.66697C12.667 7.29878 12.3685 7.0003 12.0003 7.0003L5.523 7.0003L6.64404 5.78585C6.89378 5.51531 6.87691 5.09353 6.60636 4.8438Z" fill="#3172FF"/>
                    </SVGWrapper>
                        Back
                    </BackButton>
                </BackButtonWrapper>
                }
            </HeaderLeft>
        </Header>
    )
}

export default Nav