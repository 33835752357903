import { useState } from 'react'
import { Card } from 'antd';
import styled from 'styled-components'
import { Spin, Checkbox } from 'antd'
import {
    INFT,
} from '../../common/web3/NFTget';

const { Meta } = Card;

const SDCDogsContainer = styled.div`
    width: 90%;
    height: 100%;
    margin-right: 6px;
    margin-bottom: 6px;    
    border-radius: 4px;
`

const SDCDogsImage = styled.img`
    width: 100%;
`

const SDCLockOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #000000;
`

const SDCDogsImageContainer = styled.div`
   width: 100%;
   position: relative; 

   &:hover {
       cursor: pointer;
       ${SDCLockOverlay} {
           opacity: 0.7;
       }
   }
`

const SDCLockOverlayText = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
`
const SDCLockOverlayTitle = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;

    text-align: center;
    letter-spacing: -0.015em;
    color: #rgba(255, 255, 255, 1);;

`

const SDCLockOverlayDays = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */

    text-align: center;
    letter-spacing: -0.015em;

    color: #rgba(255, 255, 255, 1);;
`

const SDCStakeImageCardContainer = styled(Card)`
    text-align: center;
    .ant-card-body {
        padding: 5px;
    }

    .ant-card-meta-title {
        white-space: normal;
        font-size: 12px;
    }
`

const SDCStakeImageContainer = styled(Meta)`
`

const SDCDogsSelectCheckboxButton = styled(Checkbox)`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    border-radius: 20px;
`

const SDCDogsSlot = styled.div`
    width: 30%;
    height: 90px;
    margin-right: 6px;
    margin-bottom: 6px;
    background: #E8E8E8;
    border-radius: 4px;
    @media (max-width: 1900px) {
        width: 80px;
        height: 80px;
        margin-right: 6px;
        margin-bottom: 6px;  
    }
    @media (max-width: 1600px) {
        width: 65px;
        height: 65px;
        margin-right: 6px;
        margin-bottom: 6px;  
    }

    @media(max-width: 1440px) {
        width: 60px;
        height: 60px;
    }
`

interface SDCStakeCardProps {
    item: INFT | null;
}



const SDCStakeCard = ({ item }: SDCStakeCardProps) => {
    const [isLoading, setIsLoading] = useState(true)

    return (
        <SDCDogsContainer>
        {item?
        <SDCDogsImageContainer>
            {isLoading && <Spin />}
            <>
            <SDCDogsSelectCheckboxButton value={item} />
              <SDCStakeImageCardContainer 
              hoverable
              cover={<SDCDogsImage src={(item.externalMetadata as any).image} onLoad={() => setIsLoading(false)}/>}
              >
                <SDCStakeImageContainer title={(item.externalMetadata as any).name.split(' ')[2]} />
            </SDCStakeImageCardContainer>
            </>
        </SDCDogsImageContainer>
        :

        <SDCDogsSlot />
        }
        </SDCDogsContainer>
    )
}

export default SDCStakeCard;
