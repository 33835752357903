import styled from "@emotion/styled";
import { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Divider, Tooltip } from "antd";
import { Spin } from "antd";
import { CalendarOutlined, EyeOutlined, LoadingOutlined } from "@ant-design/icons";
import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import Countdown, { CountdownRenderProps, CountdownApi } from "react-countdown";
import { useSnackbar } from "notistack";
import { errorMessage, TOKEN_DECIMALS } from "../../global";
import { findFarmerPDA, stringifyPKsAndBNs } from "../../sdk";
import moment from "moment";

const RentalFarmWrapper = styled.div`
  position: relative;
  padding: 12px;
  align-items: center;
  margin-top: 28px;
  display: flex;
  flex-flow: column wrap;
  background: #fcfcfd;
  /* depth/4 */
  width: 100%;
  box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
  border-radius: 20px;
`;

const RentalFarmImg = styled.img`
  width: 100%;
  border-radius: 20px;
`;

const RentalFarmInfo = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 14.5px;
`;
const RentalFarmTitle = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 650;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #0a0a0a;
  letter-spacing: 0.03em;
`;

const RentalAward = styled.div`
  background: #ffffff;
  border: 2px solid #f09ec4;
  border-radius: 4px;
  font-family: "ObviouslyBold";
  font-style: normal;
  font-weight: 660;
  font-size: 14px;
  line-height: 12px;
  /* identical to box height, or 86% */

  text-align: center;
  text-transform: uppercase;
  padding: 8px;
  color: #eb8fb3;
`;

const RentalFarmSecondaryInfo = styled(Row)`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
`;
const RarityRequirements = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  color: #3172ff;
`;

const DailySalary = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 14px;
  margin-left: auto;
  color: rgba(10, 10, 10, 0.2);
`;

const InfoDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 16px;
`;

const RentalFarmThirdInfo = styled(Row)`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;
const LockUpWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: rgba(10, 10, 10, 0.6);
`;

const StrongText = styled.span`
  margin-left: 4px;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #0a0a0a;
`;

const OpenPositions = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: rgba(10, 10, 10, 0.6);
`;

const RentalFarmFourthInfo = styled(Row)`
  margin-top: 12px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const HourseStakedNum = styled.div`
  display: flex;
  align-items: center;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: rgba(10, 10, 10, 0.6);
`;
const BonesEarnedWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const BonesEarned = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: #3172ff;
`;

const EarnedText = styled.span`
  margin-left: 4px;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  color: rgba(10, 10, 10, 0.6);
`;

const SVGWrapper = styled.svg`
  margin-right: 5.5px;
`;

const ApplyButton = styled(Button)`
  height: 40px;
  margin-top: 16px;
  width: 100%;
  background: #f98147;
  border: 1px solid #f98147;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #ffeecb;
  &:hover {
    color: #f98147;
    border: 1px solid #f98147;
  }
  &:active {
    color: #f98147;
    border: 1px solid #f98147;
  }
`;
const ViewButton = styled(Button)`
  height: 40px;
  margin-top: 16px;
  width: 100%;
  background: #f98147;
  border: 1px solid #f98147;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #ffeecb;
  &:hover {
    color: #f98147;
    border: 1px solid #f98147;
  }
  &:active {
    color: #f98147;
    border: 1px solid #f98147;
  }
`;

const ActionButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: 8px;
`;
const RefreshButton = styled(Button)`
  width: 49%;
  background: #3172ff;
  height: 40px;
  /* Blue */

  border: 1px solid #3172ff;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #e5e5e5;
`;

const ClaimButton = styled(Button)`
  height: 40px;
  width: 49%;
  background: #3172ff;
  /* Blue */

  border: 1px solid #3172ff;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #e5e5e5;
`;

const TimerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: absolute;
  left: 24px;
  top: 24px;
  background: #ffffff;
  border-radius: 4px;
  padding: 4px 12px;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 20px;
  color: #0a0a0a !important;
`;

const TimeToLockTimerWrapper = styled(Col)`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const TimeToLockTimerTitle = styled.div`
  justify-content: center;
  margin-bottom: 10px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #18a0fb;
`;

const TimeToLockTimerStats = styled(Col)`
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.015em;

  color: #18a0fb;
`;

const TimerTooltip = styled(Tooltip)`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: 0.03em;

  /* White */

  color: #e5e5e5;
`;
const MarketCard = styled(Col)``;

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const SecondsInADay = 86400;

const truncatedNumberStr = (n: number) => {
  return parseFloat(n.toFixed(2)).toString();
};

interface SDCRentalMarketInterface {
  gf: any; // gemFarm object
  item: {
    farmId: string;
    img: string;
    title: string;
    subTitle?: string;
  };
  showStakingBoard: (
    farmId: string,
    vaultId: string,
    lockupPeriod: string,
    stakeDeadline: number,
    farmFull: boolean
  ) => void;
  setFarmTotal: (farmId: string, total: number) => void;
  launchTime?: number;
  filtered?: boolean;
}

interface SDCRentalTimerInterface {
  lockupExpiring: number;
  rewardsEndTime: number;
  denominator: number;
  beginStakingTime: number;
  registered: any;
}

const StakeTimer = ({
  lockupExpiring,
  rewardsEndTime,
  denominator,
  beginStakingTime,
  registered,
}: SDCRentalTimerInterface) => {
  const [showTimer, setShowTimer] = useState(true);
  const [timerEndsAt, setTimerEndsAt] = useState(0);
  const [state, setState] = useState("none");

  useEffect(() => {
    console.log("refresh");

    const currentTime = Date.now();
    if (denominator <= 60000 || lockupExpiring <= 0) {
      // no need for timer
      setState("none");
      setTimerEndsAt(0);
      console.log("none");
    } else if (currentTime <= lockupExpiring) {
      setState("lockup");
      setTimerEndsAt(lockupExpiring);
      console.log(`lock up ${lockupExpiring}`);
    } else if (currentTime > lockupExpiring && currentTime <= rewardsEndTime) {
      const passedPeriod = Math.floor(
        (currentTime - beginStakingTime) / denominator
      );
      const nextPayDate = beginStakingTime + (passedPeriod + 1) * denominator;
      if (nextPayDate > rewardsEndTime) {
        setState("end");
        setTimerEndsAt(0);
        console.log(`end`);
      } else {
        setState("paid");
        setTimerEndsAt(nextPayDate);
        console.log(`paid ${nextPayDate}`);
      }
    }

    if (currentTime > rewardsEndTime) {
      setState("end");
      setTimerEndsAt(0);
      console.log(`end`);
    }

    setShowTimer(true);
  }, [
    showTimer,
    lockupExpiring,
    rewardsEndTime,
    denominator,
    beginStakingTime,
  ]);

  return (
    showTimer &&
    registered &&
    registered?.gemsStaked?.toNumber() > 0 &&
    state !== "none" && state !== "end" && (
      <TimerTooltip
        placement="topLeft"
        title={
          state === "lockup"
            ? "Lock up expires at"
            : state === "paid"
            ? "Next pay time"
            : ""
        }
      >
        <TimerWrapper>
          <SVGWrapper
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.00033 1.99984C4.68662 1.99984 2.00033 4.68613 2.00033 7.99984C2.00033 11.3135 4.68662 13.9998 8.00033 13.9998C11.314 13.9998 14.0003 11.3135 14.0003 7.99984C14.0003 4.68613 11.314 1.99984 8.00033 1.99984ZM0.666992 7.99984C0.666992 3.94975 3.95024 0.666504 8.00033 0.666504C12.0504 0.666504 15.3337 3.94975 15.3337 7.99984C15.3337 12.0499 12.0504 15.3332 8.00033 15.3332C3.95024 15.3332 0.666992 12.0499 0.666992 7.99984Z"
              fill="#0A0A0A"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.99967 3.3335C8.36786 3.3335 8.66634 3.63197 8.66634 4.00016V7.58814L10.9645 8.73721C11.2938 8.90187 11.4273 9.30232 11.2626 9.63164C11.098 9.96096 10.6975 10.0944 10.3682 9.92978L7.70153 8.59645C7.47568 8.48352 7.33301 8.25268 7.33301 8.00016V4.00016C7.33301 3.63197 7.63148 3.3335 7.99967 3.3335Z"
              fill="#0A0A0A"
            />
          </SVGWrapper>
          {state !== "end" && (
            <Countdown
              autoStart={true}
              date={timerEndsAt}
              renderer={({
                days,
                hours,
                minutes,
                seconds,
                completed,
              }: CountdownRenderProps) => {
                if (completed) {
                  // refresh when ends
                  setShowTimer(false);
                  return "";
                } else {
                  // Render a countdown
                  return (
                    <span>{`${days}D ${hours}H ${minutes}M ${seconds}`}</span>
                  );
                }
              }}
            />
          )}
        </TimerWrapper>
      </TimerTooltip>
    )
  );
};

const SDCRentalMarket = ({
  gf,
  item,
  showStakingBoard,
  setFarmTotal,
  launchTime,
  filtered,
}: SDCRentalMarketInterface) => {
  const [viewIsVisible, setViewIsVisible] = useState(false);
  const [isApply, setIsApply] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);

  const wallet = useAnchorWallet();
  const connection = useConnection().connection;
  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------- farm & farmer details
  const [farmAcc, setFarmAcc] = useState<any>(null);
  const [farmerAcc, setFarmerAcc] = useState<any>(null);
  const [farmerState, setFarmerState] = useState<string | null>(null);
  const [farmLockupPeriod, setFarmLockupPeriod] = useState<string | null>(null);
  const [farmStakedTotal, setFarmStakedTotal] = useState<number>(0);
  const [stakeDeadline, setStakeDeadline] = useState<number>(
    Number.MAX_SAFE_INTEGER
  );
  const [dailyRewards, setDailyRewards] = useState<string | null>(null);
  const [farmerLockupExpiring, setFarmerLockupExpiring] = useState<number>(0);
  const [farmerStakedTotal, setFarmerStakedTotal] = useState<number>(0);
  const [availableReward, setAvailableReward] = useState<number>(0);
  const [maxPositions, setMaxPositions] = useState<number>(0);
  const [denominator, setDenominator] = useState<number>(0);
  const [rewardsEnd, setRewardsEnd] = useState<number>(0);
  const [beginStakingTime, setbeginStakingTime] = useState<number>(0);
  const jobFull = maxPositions !== 0 && farmStakedTotal >= maxPositions;

  const fetchFarm = useCallback(async () => {
    const fetchedFarmAcc = await gf.fetchFarmAcc(new PublicKey(item.farmId));
    setFarmAcc(fetchedFarmAcc);

    const farmLockupInSecs =
      fetchedFarmAcc?.config?.minStakingPeriodSec?.toNumber();
    const dayStr = truncatedNumberStr(farmLockupInSecs / SecondsInADay);
    setFarmLockupPeriod(
      farmLockupInSecs ? dayStr + (dayStr === "1" ? " Day" : " Days") : "None"
    );

    setFarmStakedTotal(fetchedFarmAcc?.gemsStaked?.toNumber());
    setFarmTotal(item.farmId, fetchedFarmAcc?.gemsStaked?.toNumber());
    setMaxPositions(fetchedFarmAcc?.maxCounts?.maxGems);

    const baseRewardsRate =
      fetchedFarmAcc?.rewardA?.fixedRate?.schedule?.baseRate.toNumber();
    const denominator =
      fetchedFarmAcc?.rewardA?.fixedRate?.schedule?.denominator.toNumber();
    setDenominator(denominator * 1000);

    const rewardsPerDay = (baseRewardsRate * SecondsInADay) / denominator;
    setDailyRewards(
      truncatedNumberStr(rewardsPerDay / 10 ** TOKEN_DECIMALS) + " $BONES"
    );

    // stake deadline = duration - denominator
    if (fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber()) {
      setRewardsEnd(
        fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber() * 1000
      );
      setStakeDeadline(
        (fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber() -
          denominator) *
          1000
      );
    } else {
      setRewardsEnd(0);
      setStakeDeadline(Number.MAX_SAFE_INTEGER);
    }

    console.log(
      `farm found at ${item.farmId}:`,
      stringifyPKsAndBNs(fetchedFarmAcc)
    );
  }, [gf, item.farmId]);

  const fetchFarmer = useCallback(async () => {
    const [farmerPDA] = await findFarmerPDA(
      new PublicKey(item.farmId),
      wallet!.publicKey
    );

    const fetchedFarmerAcc = await gf.fetchFarmerAcc(farmerPDA);
    setFarmerAcc(fetchedFarmerAcc);
    setFarmerState(gf.parseFarmerState(fetchedFarmerAcc));
    setbeginStakingTime(
      fetchedFarmerAcc?.rewardA?.fixedRate?.beginStakingTs * 1000
    );

    setAvailableReward(
      fetchedFarmerAcc?.rewardA?.accruedReward
        .sub(fetchedFarmerAcc?.rewardA?.paidOutReward)
        .toNumber()
    );

    setFarmerStakedTotal(fetchedFarmerAcc?.gemsStaked?.toNumber());
    setFarmerLockupExpiring(
      fetchedFarmerAcc?.minStakingEndsTs?.toNumber() * 1000
    );

    console.log(
      `farmer found at ${wallet!.publicKey?.toBase58()}:`,
      stringifyPKsAndBNs(fetchedFarmerAcc)
    );
  }, [gf, item.farmId, wallet]);

  const initFarmer = async () => {
    setIsApply(true);

    try {
      let { txSig } = await gf.initFarmerWallet(new PublicKey(item.farmId));
      await connection.confirmTransaction(txSig);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchFarmer();

      setViewIsVisible(true);

      enqueueSnackbar(`Application success!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(`exception when applying: ${e}`);
      enqueueSnackbar(`Unable to apply: ${errorMessage(e)}`, {
        variant: "error",
      });
    }

    setIsApply(false);
  };

  const refreshFarmer = async () => {
    setIsRefreshing(true);

    try {
      let { txSig } = await gf.refreshFarmerWallet(
        new PublicKey(item.farmId),
        wallet?.publicKey
      );

      await connection.confirmTransaction(txSig);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchFarmer();

      enqueueSnackbar(`Earning refreshed!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(`exception when refresh farmer: ${e}`);
      enqueueSnackbar(`Unable to refresh earning: ${errorMessage(e)}`, {
        variant: "error",
      });
    }

    setIsRefreshing(false);
  };

  const claim = async () => {
    if (!farmAcc) {
      return;
    }

    setIsClaiming(true);
    try {
      let { txSig } = await gf.claimWallet(
        new PublicKey(item.farmId),
        farmAcc.rewardA.rewardMint!,
        farmAcc.rewardA.rewardMint!
      );

      await connection.confirmTransaction(txSig);
      await fetchFarmer();

      enqueueSnackbar(`BONES claimed!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(`exception when claiming: ${e}`);
      enqueueSnackbar(`Unable to claim tokens: ${errorMessage(e)}`, {
        variant: "error",
      });
    }
    setIsClaiming(false);
  };

  const refreshStates = () => {
    (async () => {
      if (wallet && connection && gf) {
        //reset stuff
        setFarmerAcc(null);
        setFarmerState(null);
        setAvailableReward(0);

        try {
          await fetchFarm();
          await fetchFarmer();
        } catch (e) {
          console.error(
            `exception when fetching farm&farmer data ${item.farmId}: ${e}`
          );
        }
      }
    })();
  };

  useEffect(refreshStates, [
    gf,
    connection,
    wallet,
    item.farmId,
    fetchFarm,
    fetchFarmer,
  ]);

  return (
    <>
      {!filtered && (!launchTime || Date.now() > launchTime) && (
        <MarketCard span={12}>
          <RentalFarmWrapper>
            <StakeTimer
              lockupExpiring={farmerLockupExpiring}
              rewardsEndTime={rewardsEnd}
              denominator={denominator}
              beginStakingTime={beginStakingTime}
              registered={farmerAcc}
            />
            <RentalFarmImg src={item.img}></RentalFarmImg>
            <RentalFarmInfo>
              <RentalFarmTitle>{item.title}</RentalFarmTitle>
              <RentalAward>{dailyRewards}</RentalAward>
            </RentalFarmInfo>
            <RentalFarmSecondaryInfo>
              {item.subTitle && (
                <RarityRequirements>{item.subTitle}</RarityRequirements>
              )}
              <DailySalary>Daily Rent</DailySalary>
            </RentalFarmSecondaryInfo>
            <InfoDivider />
            <RentalFarmThirdInfo>
              <LockUpWrapper>
                <SVGWrapper
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.16699 10.0002C3.70675 10.0002 3.33366 10.3733 3.33366 10.8335V16.6668C3.33366 17.1271 3.70675 17.5002 4.16699 17.5002H15.8337C16.2939 17.5002 16.667 17.1271 16.667 16.6668V10.8335C16.667 10.3733 16.2939 10.0002 15.8337 10.0002H4.16699ZM1.66699 10.8335C1.66699 9.45278 2.78628 8.3335 4.16699 8.3335H15.8337C17.2144 8.3335 18.3337 9.45278 18.3337 10.8335V16.6668C18.3337 18.0475 17.2144 19.1668 15.8337 19.1668H4.16699C2.78628 19.1668 1.66699 18.0475 1.66699 16.6668V10.8335Z"
                    fill="#6B6B6B"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10 2.50016C9.11595 2.50016 8.2681 2.85135 7.64298 3.47647C7.01786 4.10159 6.66667 4.94944 6.66667 5.8335V9.16683C6.66667 9.62707 6.29357 10.0002 5.83333 10.0002C5.3731 10.0002 5 9.62707 5 9.16683V5.8335C5 4.50741 5.52678 3.23564 6.46447 2.29796C7.40215 1.36028 8.67392 0.833496 10 0.833496C11.3261 0.833496 12.5979 1.36028 13.5355 2.29796C14.4732 3.23564 15 4.50741 15 5.8335V9.16683C15 9.62707 14.6269 10.0002 14.1667 10.0002C13.7064 10.0002 13.3333 9.62707 13.3333 9.16683V5.8335C13.3333 4.94944 12.9821 4.10159 12.357 3.47647C11.7319 2.85135 10.8841 2.50016 10 2.50016Z"
                    fill="#6B6B6B"
                  />
                </SVGWrapper>
                Lock Up
              </LockUpWrapper>
              <StrongText> {farmLockupPeriod} </StrongText>
            </RentalFarmThirdInfo>

            <RentalFarmFourthInfo>
              <HourseStakedNum>
                <EyeOutlined style={{ fontSize: "18px", marginRight: "8px" }} />
                Available Units
              </HourseStakedNum>
              <StrongText>
                {maxPositions
                  ? maxPositions - farmStakedTotal + " / " + maxPositions
                  : "Unlimited"}
              </StrongText>
            </RentalFarmFourthInfo>

            <RentalFarmFourthInfo>
              <HourseStakedNum>
                <CalendarOutlined style={{ fontSize: "18px", marginRight: "8px" }} />
                Closed On
              </HourseStakedNum>
              <StrongText>
                {stakeDeadline !== Number.MAX_SAFE_INTEGER
                  ? moment(new Date(stakeDeadline)).format("MMM Do YY, h:mm a")
                  : "None"}
              </StrongText>
            </RentalFarmFourthInfo>

            {farmerAcc && (
              <RentalFarmFourthInfo>
                <HourseStakedNum>
                  <SVGWrapper
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.48871 1.00904C9.78963 0.774983 10.211 0.774983 10.5119 1.00904L18.0119 6.84237C18.2149 7.00025 18.3337 7.243 18.3337 7.50016V16.6668C18.3337 17.3299 18.0703 17.9658 17.6014 18.4346C17.1326 18.9034 16.4967 19.1668 15.8337 19.1668H4.16699C3.50395 19.1668 2.86807 18.9034 2.39923 18.4346C1.93038 17.9658 1.66699 17.3299 1.66699 16.6668V7.50016C1.66699 7.243 1.78572 7.00025 1.98871 6.84237L9.48871 1.00904ZM3.33366 7.90773V16.6668C3.33366 16.8878 3.42146 17.0998 3.57774 17.2561C3.73402 17.4124 3.94598 17.5002 4.16699 17.5002H15.8337C16.0547 17.5002 16.2666 17.4124 16.4229 17.2561C16.5792 17.0998 16.667 16.8878 16.667 16.6668V7.90773L10.0003 2.72255L3.33366 7.90773Z"
                      fill="#6B6B6B"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6.66699 9.99984C6.66699 9.5396 7.04009 9.1665 7.50033 9.1665H12.5003C12.9606 9.1665 13.3337 9.5396 13.3337 9.99984V18.3332C13.3337 18.7934 12.9606 19.1665 12.5003 19.1665C12.0401 19.1665 11.667 18.7934 11.667 18.3332V10.8332H8.33366V18.3332C8.33366 18.7934 7.96056 19.1665 7.50033 19.1665C7.04009 19.1665 6.66699 18.7934 6.66699 18.3332V9.99984Z"
                      fill="#6B6B6B"
                    />
                  </SVGWrapper>
                  House Staked
                </HourseStakedNum>
                <StrongText> {farmerStakedTotal}</StrongText>
              </RentalFarmFourthInfo>
            )}

            {farmerAcc && (
              <RentalFarmFourthInfo>
                <HourseStakedNum>🔥 &nbsp; Earned</HourseStakedNum>
                <BonesEarned>
                  {availableReward / 10 ** TOKEN_DECIMALS} $BONES
                </BonesEarned>
              </RentalFarmFourthInfo>
            )}

            {!farmerAcc ? (
              <ApplyButton
                onClick={initFarmer}
                disabled={isApply || jobFull || Date.now() > stakeDeadline}
              >
                {isApply ? (
                  <Spin indicator={antIcon} />
                ) : jobFull ? (
                  `FULL`
                ) : Date.now() > stakeDeadline ? (
                  `CLOSED`
                ) : (
                  `APPLY`
                )}
              </ApplyButton>
            ) : (
              <>
                <ViewButton
                  onClick={() => {
                    showStakingBoard(
                      item.farmId,
                      farmerAcc?.vault?.toBase58(),
                      farmLockupPeriod ?? "None",
                      stakeDeadline,
                      maxPositions !== 0 && farmStakedTotal >= maxPositions
                    );
                  }}
                >
                  VIEW
                </ViewButton>
                <ActionButtonWrapper>
                  <RefreshButton
                    onClick={refreshFarmer}
                    disabled={!farmerAcc || isRefreshing}
                  >
                    {isRefreshing ? <Spin indicator={antIcon} /> : `REFRESH`}
                  </RefreshButton>

                  <ClaimButton
                    disabled={!farmerAcc || availableReward <= 0 || isClaiming}
                    onClick={claim}
                  >
                    {isClaiming ? <Spin indicator={antIcon} /> : `CLAIM`}
                  </ClaimButton>
                </ActionButtonWrapper>
              </>
            )}
          </RentalFarmWrapper>
        </MarketCard>
      )}
    </>
  );
};
export default SDCRentalMarket;
