import styled from "styled-components";
import { useState, useContext, useEffect, useCallback } from "react";
import { Row, Col, Button, Radio, Divider, Collapse, Switch } from "antd";
import CountDown from "../CountDown";
import { SDCRentMeter } from "../SDCMeter";
import SDCRentalMarket from "../SDCRentMarket/SDCRentalMarket";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import RentStakingPanel from "../RentStakingPanel/RentStakingPanel";
import {
  RentalContext,
  SDCRentalsProps,
  ISDCRentals,
} from "../../contexts/RentalContext";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { SignerWalletAdapter } from "@solana/wallet-adapter-base";
import { initGemBank } from "../../common/gem-bank";
import { initGemFarm } from "../../common/gem-farm";
import { PublicKey } from "@solana/web3.js";

const { Panel } = Collapse;

const RentWrapperPanel = styled(Row)`
  // margin-top: 0px;
`;

const FilterButtonsWrapper = styled.div``;

const FilterRadioGroup = styled(Radio.Group)`
  display: flex;
  flex-flow: row wrap;
  .ant-radio-button-wrapper:first-child {
    border: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    background: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: #3172ff;
    border-radius: 100px;
    color: #fcfcfd;
  }
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    border: none;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none;
  }
`;

const FilterButton = styled(Radio.Button)`
  border: none;
  background: none;
  font-family: "Obviously";
  font-weight: 660;
  font-size: 14px;
  letter-spacing: 0.03em;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const RentalMarketsContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const RentPageTitle = styled.div`
    width: fit-content;
    margin-top: 50px;
    margin-bottom: 32px;
    font-family: "ObviouslyBold";
    font-style: normal;
    font-weight: normal;
    font-size: 42px;
    color: #30AAD1;
    background: -webkit-linear-gradient(45deg, #30AAD1, #EB8FB3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
`

const RentalMarketsTitle = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 660;
  font-size: 22px;
  line-height: 24px;
  /* identical to box height, or 75% */

  letter-spacing: 0.02em;

  color: #0a0a0a;
`;
const RentalSwitch = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
const RentalSwitchTitle = styled.div`
  font-family: "Obviously";
  font-style: normal;
  font-weight: 660;
  font-size: 14px;
  letter-spacing: 0.03em;
`;
const RentLevelCollapse = styled(Collapse)`
  margin-top: 15px;
  width: 100%;
  background: none;
  border: none;
  .ant-collapse-content {
    background: none;
    border: none;
    padding-bottom: 72px;
  }
`;
const EntryLevel = styled(Panel)`
  width: 100%;
`;
const EntryLevelMarketWrapper = styled(Row)`
  width: 100%;
`;
const EntryLevelRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
`;
const RentLevelTitle = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 660;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height, or 75% */

  letter-spacing: 0.02em;

  color: #0a0a0a;
`;

const TitleDivider = styled(Divider)`
  margin-bottom: 2px;
`;

const ExpandIcon = styled.span`
  display: flex;
  flex-flow: column;
  justify-content: center;
`;

const BonusLevelTitle = styled.div`
  width: 100%;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 133% */

  color: #3172ff;
`;

const RentDescription = styled.div`
  text-align: center;
  width: 100%;
  font-family: 'Obviously';
  font-style: normal;
  font-weight: 660;
  font-size: 18px;
  line-height: 28px;
  margin-top: 88px;
  margin-bottom: 50px;
  /* or 109% */

  letter-spacing: -0.02em;
  padding-left: 10%;
  padding-right: 10%;


  color: #3172ff;
`;

const DownExpand = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.9142 19.5858C32.1332 18.8047 30.8668 18.8047 30.0858 19.5858L24.5 25.1716L18.9142 19.5858C18.1332 18.8047 16.8668 18.8047 16.0858 19.5858C15.3047 20.3668 15.3047 21.6332 16.0858 22.4142L23.0858 29.4142C23.8668 30.1953 25.1332 30.1953 25.9142 29.4142L32.9142 22.4142C33.6953 21.6332 33.6953 20.3668 32.9142 19.5858Z"
        fill="#23262F"
      />
    </svg>
  );
};

const UpExpand = () => {
  return (
    <svg
      width="49"
      height="48"
      viewBox="0 0 49 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.0858 28.4142C16.8668 29.1953 18.1332 29.1953 18.9142 28.4142L24.5 22.8284L30.0858 28.4142C30.8668 29.1953 32.1332 29.1953 32.9142 28.4142C33.6953 27.6332 33.6953 26.3668 32.9142 25.5858L25.9142 18.5858C25.1332 17.8047 23.8668 17.8047 23.0858 18.5858L16.0858 25.5858C15.3047 26.3668 15.3047 27.6332 16.0858 28.4142Z"
        fill="#23262F"
      />
    </svg>
  );
};

interface RentMarketInterface {
  rentStakingIsVisible: boolean;
  showRentStaking: () => void;
}

const SDCRent = ({
  rentStakingIsVisible,
  showRentStaking,
}: RentMarketInterface) => {
  const [gf, setGf] = useState<any>(null);
  const [gb, setGb] = useState<any>(null);
  const [stakingPanelFarmId, setStakingPanelFarmId] =
    useState<PublicKey | null>(null);
  const [stakingPanelVaultId, setStakingPanelVaultId] =
    useState<PublicKey | null>(null);
  const [farmLockupPeriod, setFarmLockupPeriod] = useState<string | null>(null);
  const [farmStakedTotals, setFarmStakedTotals] = useState({});
  const [farmStakeDeadline, setFarmStakeDeadline] = useState(
    Number.MAX_SAFE_INTEGER
  );
  const [farmFull, setFarmFull] = useState(false);

  const [tier4expanded, setTier4expanded] = useState(true);
  const [tier3expanded, setTier3expanded] = useState(true);
  const [tier2expanded, setTier2expanded] = useState(true);
  const [tier1expanded, setTier1expanded] = useState(true);
  const [tier0expanded, setTier0expanded] = useState(true);
  const [tier00expanded, setTier00expanded] = useState(true);

  const [tier4key, setTier4key] = useState(["0"]);
  const [tier3key, setTier3key] = useState(["0"]);
  const [tier2key, setTier2key] = useState(["0"]);
  const [tier1key, setTier1key] = useState(["0"]);
  const [tier0key, setTier0key] = useState(["0"]);
  const [tier00key, setTier00key] = useState(["0"]);

  const { studentHousing, coolCrib, poshPad, topDigs, dynomite, specialty } =
    useContext<SDCRentalsProps>(RentalContext);

  const wallet = useAnchorWallet();
  const connection = useConnection().connection;

  const [filterValue, setFilterValue] = useState("active");
  const [expanded, setExpanded] = useState(true);
  const [filteredRentals, setFilteredRentals] = useState({
    studentHousing: studentHousing.filter((j) => !j.ended),
    coolCrib: coolCrib.filter((j) => !j.ended),
    poshPad: poshPad.filter((j) => !j.ended),
    topDigs: topDigs.filter((j) => !j.ended),
    dynomite: dynomite.filter((j) => !j.ended),
    specialty: specialty.filter((j) => !j.ended),
  });

  const onFilterChange = (e: any) => {
    setFilterValue(e.target.value);
  };

  const onExpandChange = (checked: boolean) => {
    setExpanded(checked);

    if (checked) {
      setTier00expanded(true);
      setTier0expanded(true);
      setTier1expanded(true);
      setTier2expanded(true);
      setTier3expanded(true);
      setTier4expanded(true);
    } else {
      setTier00expanded(false);
      setTier0expanded(false);
      setTier1expanded(false);
      setTier2expanded(false);
      setTier3expanded(false);
      setTier4expanded(false);
    }
  };

  useEffect(() => {
    if (tier4expanded) {
      setTier4key(["0"]);
    } else {
      setTier4key([]);
    }
  }, [tier4expanded]);

  useEffect(() => {
    if (tier3expanded) {
      setTier3key(["0"]);
    } else {
      setTier3key([]);
    }
  }, [tier3expanded]);

  useEffect(() => {
    if (tier2expanded) {
      setTier2key(["0"]);
    } else {
      setTier2key([]);
    }
  }, [tier2expanded]);

  useEffect(() => {
    if (tier1expanded) {
      setTier1key(["0"]);
    } else {
      setTier1key([]);
    }
  }, [tier1expanded]);

  useEffect(() => {
    if (tier0expanded) {
      setTier0key(["0"]);
    } else {
      setTier0key([]);
    }
  }, [tier0expanded]);

  useEffect(() => {
    if (tier00expanded) {
      setTier00key(["0"]);
    } else {
      setTier00key([]);
    }
  }, [tier00expanded]);

  const setStakedValue = (farmId: string, total: number) => {
    setFarmStakedTotals((prevState) => {
      return { ...prevState, ...{ [farmId]: total } };
    });
  };

  const getTotalStakedRatio = () => {
    let total: number = 0;

    for (const [key, value] of Object.entries(farmStakedTotals)) {
      total += value as number;
    }

    const ratio = (total * 100) / 4999;

    return parseFloat(ratio.toFixed(2));
  };

  const initGemFarms = useCallback(() => {
    (async () => {
      if (wallet && connection) {
        const gemFarm = await initGemFarm(
          connection,
          wallet as SignerWalletAdapter
        );
        setGf(gemFarm);
      }
    })();
  }, [wallet, connection]);

  const initGemBanks = useCallback(() => {
    (async () => {
      if (wallet && connection) {
        const gemBank = await initGemBank(
          connection,
          wallet as SignerWalletAdapter
        );
        setGb(gemBank);
      }
    })();
  }, [wallet, connection]);

  useEffect(() => {
    initGemFarms();
    initGemBanks();
  }, [wallet, connection, initGemFarms, initGemBanks]);

  useEffect(() => {
    if (filterValue === "ended") {
      setFilteredRentals({
        studentHousing: studentHousing.filter((j) => j.ended),
        coolCrib: coolCrib.filter((j) => j.ended),
        poshPad: poshPad.filter((j) => j.ended),
        topDigs: topDigs.filter((j) => j.ended),
        dynomite: dynomite.filter((j) => j.ended),
        specialty: specialty.filter((j) => j.ended),
      });
    } else {
      setFilteredRentals({
        studentHousing: studentHousing.filter((j) => !j.ended),
        coolCrib: coolCrib.filter((j) => !j.ended),
        poshPad: poshPad.filter((j) => !j.ended),
        topDigs: topDigs.filter((j) => !j.ended),
        dynomite: dynomite.filter((j) => !j.ended),
        specialty: specialty.filter((j) => !j.ended),
      });
    }
  }, [filterValue]);

  const openStakingPanelForId = (
    farmId: string,
    vaultId: string,
    lockupPeriod: string,
    stakeDeadline: number,
    farmFull: boolean
  ) => {
    setStakingPanelFarmId(new PublicKey(farmId));
    setStakingPanelVaultId(new PublicKey(vaultId));
    setFarmLockupPeriod(lockupPeriod);
    setFarmStakeDeadline(stakeDeadline);
    setFarmFull(farmFull);
    showRentStaking();
  };

  return (
    <>
      {rentStakingIsVisible && stakingPanelFarmId && stakingPanelVaultId ? (
        <RentStakingPanel
          gf={gf}
          gb={gb}
          farmId={stakingPanelFarmId}
          vaultId={stakingPanelVaultId}
          lockupPeriod={farmLockupPeriod ?? "None"}
          stakingDeadline={farmStakeDeadline}
          jobFull={farmFull}
        />
      ) : (
        <>
          {!wallet && (
            <RentDescription>{"Please connect your wallet"}</RentDescription>
          )}
          {wallet && (
            <>
              <RentWrapperPanel>
                <RentPageTitle>Rent</RentPageTitle>
                <SDCRentMeter meterValue={getTotalStakedRatio()} />
                <RentDescription>
                    {`Earn $BONES when you rent out your Dog House. Higher rarity homes earn more rental income!`}
                </RentDescription>
                <RentalMarketsContainer>
                  <RentalMarketsTitle>Rentals Markets</RentalMarketsTitle>
                  <RentalSwitch>
                    <RentalSwitchTitle>
                      {expanded ? "Expanded" : "Collapsed"}
                    </RentalSwitchTitle>
                    <Switch onChange={onExpandChange} checked={expanded} />
                  </RentalSwitch>
                  <FilterButtonsWrapper>
                    <FilterRadioGroup
                      onChange={onFilterChange}
                      value={filterValue}
                    >
                      <FilterButton value="active">Active</FilterButton>
                      <FilterButton value="ended">Closed</FilterButton>
                    </FilterRadioGroup>
                  </FilterButtonsWrapper>
                </RentalMarketsContainer>
                <TitleDivider />
                {/* Tier 4  */}
                <RentLevelCollapse
                  activeKey={tier4key}
                  onChange={() => setTier4expanded(!tier4expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <RentLevelTitle>Student Housing</RentLevelTitle>
                        {tier4expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {studentHousing.map(
                        (item: ISDCRentals, index: number) => (
                          <SDCRentalMarket
                            key={index.toString()}
                            item={item}
                            filtered={
                              !filteredRentals.studentHousing.includes(item)
                            }
                            launchTime={item.launchTime}
                            showStakingBoard={openStakingPanelForId}
                            setFarmTotal={setStakedValue}
                            gf={gf}
                          />
                        )
                      )}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
                {/* Tier 3  */}
                <RentLevelCollapse
                  activeKey={tier3key}
                  onChange={() => setTier3expanded(!tier3expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <BonusLevelTitle>1.5x Bonus!</BonusLevelTitle>
                        <RentLevelTitle>Cool Crib</RentLevelTitle>
                        {tier3expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {coolCrib.map((item: ISDCRentals, index: number) => (
                        <SDCRentalMarket
                          key={index.toString()}
                          item={item}
                          filtered={!filteredRentals.coolCrib.includes(item)}
                          launchTime={item.launchTime}
                          showStakingBoard={openStakingPanelForId}
                          setFarmTotal={setStakedValue}
                          gf={gf}
                        />
                      ))}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
                {/* Tier 2  */}

                <RentLevelCollapse
                  activeKey={tier2key}
                  onChange={() => setTier2expanded(!tier2expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <BonusLevelTitle>2x Bonus!</BonusLevelTitle>
                        <RentLevelTitle>Posh Pad</RentLevelTitle>
                        {tier2expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {poshPad.map((item: ISDCRentals, index: number) => (
                        <SDCRentalMarket
                          key={index.toString()}
                          item={item}
                          filtered={!filteredRentals.poshPad.includes(item)}
                          launchTime={item.launchTime}
                          showStakingBoard={openStakingPanelForId}
                          setFarmTotal={setStakedValue}
                          gf={gf}
                        />
                      ))}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
                {/* Tier 1  */}
                <RentLevelCollapse
                  activeKey={tier1key}
                  onChange={() => setTier1expanded(!tier1expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <BonusLevelTitle>5x Bonus!</BonusLevelTitle>
                        <RentLevelTitle>Top Digs</RentLevelTitle>
                        {tier1expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {topDigs.map((item: ISDCRentals, index: number) => (
                        <SDCRentalMarket
                          key={index.toString()}
                          item={item}
                          filtered={!filteredRentals.topDigs.includes(item)}
                          launchTime={item.launchTime}
                          showStakingBoard={openStakingPanelForId}
                          setFarmTotal={setStakedValue}
                          gf={gf}
                        />
                      ))}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
                {/* Tier 0  */}
                <RentLevelCollapse
                  activeKey={tier0key}
                  onChange={() => setTier0expanded(!tier0expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <BonusLevelTitle>7.5x Bonus!</BonusLevelTitle>
                        <RentLevelTitle>Specialty</RentLevelTitle>
                        {tier0expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {dynomite.map((item: ISDCRentals, index: number) => (
                        <SDCRentalMarket
                          key={index.toString()}
                          item={item}
                          filtered={!filteredRentals.dynomite.includes(item)}
                          launchTime={item.launchTime}
                          showStakingBoard={openStakingPanelForId}
                          setFarmTotal={setStakedValue}
                          gf={gf}
                        />
                      ))}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
                {/* Tier 00  */}
                <RentLevelCollapse
                  activeKey={tier00key}
                  onChange={() => setTier00expanded(!tier00expanded)}
                >
                  <EntryLevel
                    showArrow={false}
                    header={
                      <EntryLevelRow>
                        <BonusLevelTitle>10x Bonus!</BonusLevelTitle>
                        <RentLevelTitle>Icons</RentLevelTitle>
                        {tier00expanded ? (
                          <ExpandIcon>
                            <UpExpand />
                          </ExpandIcon>
                        ) : (
                          <ExpandIcon>
                            <DownExpand />
                          </ExpandIcon>
                        )}
                      </EntryLevelRow>
                    }
                    key="0"
                  >
                    <EntryLevelMarketWrapper gutter={[16, 24]}>
                      {specialty.map((item: ISDCRentals, index: number) => (
                        <SDCRentalMarket
                          key={index.toString()}
                          item={item}
                          filtered={!filteredRentals.specialty.includes(item)}
                          launchTime={item.launchTime}
                          showStakingBoard={openStakingPanelForId}
                          setFarmTotal={setStakedValue}
                          gf={gf}
                        />
                      ))}
                    </EntryLevelMarketWrapper>
                  </EntryLevel>
                </RentLevelCollapse>
              </RentWrapperPanel>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SDCRent;
