import { FC } from 'react';
import styled from 'styled-components';
import {
    WalletMultiButton
} from '@solana/wallet-adapter-react-ui';

// Default styles that can be overridden by your app
require('@solana/wallet-adapter-react-ui/styles.css');

const WalletButton = styled(WalletMultiButton)`
    width: 100%;
    height: 40px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    background: #3172FF;
    border-radius: 4px;
    font-family: 'Obviously550';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: #FFFFFF;
`
const Wallet: FC = () => {
    return (
        <div>
            <WalletButton />
        </div>
    );
};

export default Wallet;
