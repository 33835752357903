import { useState, useEffect, useCallback, useContext } from "react";
import styled from "styled-components";
import { Button, Checkbox, Row, Col, Spin, Modal, Divider } from "antd";
import SDCHouseStakeCard from "../SDCStakeCard/SDCHouseStakeCard";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { PublicKey } from "@solana/web3.js";
import {
  getNFTsByOwner,
  getNFTMetadataForMany,
} from "../../common/web3/NFTget";
import { NETWORK, DOGHOUSE_CM_ID, errorMessage } from "../../global";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import { BN } from "@project-serum/anchor";
import { stringifyPKsAndBNs, findFarmerPDA } from "../../sdk";
import { useSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { RentalContext, SDCRentalsProps } from "../../contexts/RentalContext";

const StakingPanelContainer = styled(Row)`
  display: flex;
  flex-flow: row wrap;
  margin-top: 48px;
  width: 100%;
`;
const AvailableHouseContainer = styled.div`
  flex: 40%;
`;

const CheckboxGroup = styled(Checkbox.Group)`
  width: 100%;
`;

const AvailableHouseRow = styled(Row)``;

const ActionButtons = styled.div`
  margin-left: 0 auto;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-flow: column wrap;
  flex: 20%;
`;

const ActionButton = styled(Button)`
  width: 140px;
  margin: 10px;
  height: 40px;
  border: none;
  font-family: "Obviously550";
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  color: #f8f9fa;
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
  background: #3172ff;
  border-radius: 4px;
  &:hover {
    background: #3172ff;
    color: #eeebeb;
    opacity: 0.9;
  }
  &:focus {
    background: #3172ff;
    color: #eeebeb;
  }
  &:active {
    background: #3172ff;
    color: #eeebeb;
  }
  &:disabled {
    background: rgba(10, 10, 10, 0.1);
    opacity: 0.4;
    border-radius: 4px;
    color: #0a0a0a;
    &:hover {
      background: linear-gradient(
          311.99deg,
          rgba(0, 0, 0, 0.5) -22.55%,
          rgba(255, 255, 255, 0.5) 131.34%
        ),
        #939099;
      color: #0a0a0a;
      opacity: 0.3;
    }
  }
`;

const Title = styled.div`
  font-family: "ObviouslyBold";
  font-style: normal;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 100% */

  letter-spacing: -0.02em;

  color: #3172ff;
`;

const AvailableTitle = styled(Title)`
  flex: 60%;
`;

const RentalsTitle = styled(Title)`
  flex: 40%;
`;

const StakedHouseContainer = styled.div`
  flex: 40%;
`;

const StakedHouseRow = styled(Row)``;

const StartStakingContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column wrap;
  text-align: left;
`;

const StartStakingTextContainer = styled.div`
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  width: 100%;
  display: flex;
  flex-flow: column wrap;
  padding: 16px;
`;

const StartStakingTextTitle = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  letter-spacing: -0.04em;
  margin-bottom: 4px;
  /* Grey 4 */

  color: rgba(10, 10, 10, 0.8);
`;

const StartStakingText = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 12px;
  line-height: 20px;
  /* or 167% */
  margin-right: 4px;
  letter-spacing: -0.02em;

  /* Grey 3 */

  color: rgba(10, 10, 10, 0.6);
`;

const StakingFinishedText = styled(StartStakingText)``;

const ModalStakeButton = styled(ActionButton)`
  width: 100%;
`;

const CancelButton = styled(ActionButton)`
  background: linear-gradient(
      311.99deg,
      rgba(0, 0, 0, 0.5) -22.55%,
      rgba(255, 255, 255, 0.5) 131.34%
    ),
    #a4a4a4;
  &:hover {
    background: #a4a4a4;
    color: white;
  }
`;

const TimeToLockTimerStats = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  letter-spacing: -0.04em;
  display: flex;
  flex-flow: row wrap;
  color: rgba(10, 10, 10, 0.6);
`;

const TimerText = styled.div`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  letter-spacing: -0.04em;

  /* Grey 3 */

  color: black;
`;

const StakingModalContainer = styled(Modal)`
  .ant-modal-header {
    background: #ffffff;
    /* Grey 3 */
    border-top: 1px solid #f6f7fb;
    border-bottom: none;
    /* Modal shadow */

    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 4px;
  }

  .ant-modal-title {
    font-family: "Obviously550";
    font-style: normal;
    font-weight: 570;
    font-size: 32px;
    line-height: 36px;
    /* identical to box height, or 112% */

    /* Black */

    color: #0a0a0a;
  }
  .ant-modal-content {
    background: #ffffff;
    /* Grey 3 */

    border-bottom: 1px solid #f6f7fb;
    /* Modal shadow */

    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 4px;
  }

  .ant-modal-body {
    font-family: "Obviously550";
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    /* or 117% */

    /* Grey 3 */

    color: rgba(10, 10, 10, 0.6);
  }

  .ant-modal-footer {
    background: #ffffff;
    /* Grey 3 */

    border-bottom: 1px solid #f6f7fb;
    border-top: none;

    box-shadow: 0px 64px 64px -48px rgba(31, 47, 70, 0.12);
    border-radius: 4px;
    display: flex;
    justify-content: center;
  }
`;
const CheckAllContainer = styled.div`
  width: 100%;
  justify-content: space-between;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 14px;
  /* or 100% */

  display: flex;
  align-items: flex-end;

  /* Grey 3 */

  color: rgba(10, 10, 10, 0.6);
  margin-bottom: 24px;
  display: flex;
  flex-flow: row wrap;
`;

const CheckboxTitle = styled.div`
  margin-right: 6.4px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
`;

const Titles = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-right: 50px;
`;

const TitleDivider = styled(Divider)``;

const HouseContainer = styled.div`
  display: flex;
  width: 100%;
`;

const HouseCountTitle = styled.span`
  font-family: "Obviously550";
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  /* identical to box height, or 100% */

  text-transform: uppercase;

  color: #0a0a0a;
`;

const NoStakedHouseImg = styled.img`
  width: 210px;
`;

const StakeButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  background: #f98147;
  border: 1px solid #f98147;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #ffeecb;
  &:hover {
    color: #f98147;
    border: 1px solid #f98147;
  }
  &:active {
    color: #f98147;
    border: 1px solid #f98147;
  }
`;
// .ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active
const UnStakeButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  background: #f98147;
  border: 1px solid #f98147;
  border-radius: 6px;
  //TODO: 570;
  font-family: "Obviously550";
  font-style: normal;
  font-weight: 570;
  font-size: 14px;
  color: #ffeecb;
  &:hover {
    color: #f98147;
    border: 1px solid #f98147;
  }
  &:active {
    color: #f98147;
    border: 1px solid #f98147;
  }
`;

const StakeButtonWrapper = styled.div`
  padding: 8px 0;
`;

export interface StakingPanelInterface {
  gf: any;
  gb: any;
  farmId: PublicKey;
  vaultId: PublicKey;
  lockupPeriod: string;
  stakingDeadline: number;
  jobFull: boolean;
}

const isTimeExpired = (endTime: number, refreshSeed?: number) => {
  return Date.now() > endTime;
};

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

const RentStakingPanel = ({
  gf,
  gb,
  farmId,
  vaultId,
  lockupPeriod,
  stakingDeadline,
  jobFull,
}: StakingPanelInterface) => {
  const [bank, setBank] = useState<PublicKey | null>(null);
  const [vaultLocked, setVaultLocked] = useState<boolean>(false);
  const [farmerState, setFarmerState] = useState<string | null>(null);
  const [farmerLockupExpiring, setFarmerLockupExpiring] = useState<number>(0);
  const [currentWalletNFTs, setCurrentWalletNFTs] = useState<any[]>([]);
  const [currentVaultNFTs, setCurrentVaultNFTs] = useState<any[]>([]);
  const [selectedWalletNFTs, setSelectedWalletNFTs] = useState<any[]>([]);
  const [selectedVaultNFTs, setSelectedVaultNFTs] = useState<any[]>([]);
  const [refreshSeed, setRefreshSeed] = useState(0);
  const [indeterminateAvailable, setIndeterminateAvailable] = useState(false);
  const [checkAllAvailable, setCheckAllAvailable] = useState(false);
  const [indeterminateWorkforce, setIndeterminateWorkforce] = useState(false);
  const [checkAllWorkforce, setCheckAllWorkforce] = useState(false);

  // UI states
  const [isProcessing, setIsProcessing] = useState(false);
  const [isLoadingWalletNFTs, setIsLoadingWalletNFTs] = useState(false);
  const [isLoadingVaultNFTs, setIsLoadingVaultNFTs] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  // --------------------------------------- web3
  const wallet = useAnchorWallet();
  const connection = useConnection().connection;
  const { studentHousing, coolCrib, poshPad, topDigs, dynomite, specialty } =
    useContext<SDCRentalsProps>(RentalContext);
  const allFarms = [
    ...studentHousing,
    ...coolCrib,
    ...poshPad,
    ...topDigs,
    ...dynomite,
    ...specialty,
  ];

  const onAvailableHouseSelect = (value: any) => {
    setSelectedWalletNFTs(value);
    setIndeterminateAvailable(
      !!value.length && value.length < currentWalletNFTs.length
    );
    setCheckAllAvailable(value.length === currentWalletNFTs.length);
  };

  const onStakedHouseSelect = (value: any) => {
    setSelectedVaultNFTs(value);
    setIndeterminateWorkforce(
      !!value.length && value.length < currentVaultNFTs.length
    );
    setCheckAllWorkforce(value.length === currentVaultNFTs.length);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    beginStaking();
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showConfirmModal = () => {
    setIsModalVisible(true);
  };

  const populateWalletNFTs = useCallback(async () => {
    if (!wallet || !connection) {
      return;
    }

    const farmConfig = allFarms.find((f) => f.farmId === farmId.toBase58());

    setIsLoadingWalletNFTs(true);

    const fetchedNFTs = await getNFTsByOwner(
      wallet.publicKey!,
      connection,
      (metadata) => {
        let network = NETWORK;

        if (network === "mainnet-beta") {
          let whitelistFunc = farmConfig?.onChainwhitelistCriteria;
          // default to only show dog houses
          if (!whitelistFunc) {
            whitelistFunc = (metadata) => {
              return metadata.data.creators[0].address === DOGHOUSE_CM_ID;
            };
          }

          return whitelistFunc(metadata);
        } else {
          return true;
        }
      }
    );

    const externalFilter = farmConfig?.externalMetadataCriteria;

    if (externalFilter) {
      setCurrentWalletNFTs(
        fetchedNFTs.filter((nft) => externalFilter(nft.externalMetadata))
      );
    } else {
      setCurrentWalletNFTs(fetchedNFTs);
    }

    setIsLoadingWalletNFTs(false);
  }, [wallet, connection]);

  const populateVaultNFTs = useCallback(async () => {
    if (!wallet || !connection) {
      return;
    }

    setIsLoadingVaultNFTs(true);

    const foundGDRs = await gb.fetchAllGdrPDAs(vaultId);
    if (foundGDRs && foundGDRs.length) {
      console.log(`found a total of ${foundGDRs.length} gdrs`);

      const mints = foundGDRs.map((gdr: any) => {
        return { mint: gdr.account.gemMint };
      });

      const fetchedNFTs = await getNFTMetadataForMany(mints, connection);
      console.log(`populated a total of ${fetchedNFTs.length} vault NFTs`);
      setCurrentVaultNFTs(fetchedNFTs);
    } else {
      setCurrentVaultNFTs([]);
    }

    setIsLoadingVaultNFTs(false);
  }, [connection, wallet, gb, vaultId]);

  const fetchFarmer = useCallback(async () => {
    const [farmerPDA] = await findFarmerPDA(farmId, wallet!.publicKey);

    const fetchedFarmerAcc = await gf.fetchFarmerAcc(farmerPDA);
    console.log("farmer state " + gf.parseFarmerState(fetchedFarmerAcc));
    setFarmerState(gf.parseFarmerState(fetchedFarmerAcc));
    setFarmerLockupExpiring(
      fetchedFarmerAcc?.minStakingEndsTs?.toNumber() * 1000
    );

    console.log(
      `farmer found at ${wallet!.publicKey?.toBase58()}:`,
      stringifyPKsAndBNs(fetchedFarmerAcc)
    );
  }, [farmId, gf, wallet]);

  const updateVaultState = useCallback(async () => {
    try {
      const vAcc = await gb.fetchVaultAcc(vaultId);
      setBank(vAcc.bank);
      setVaultLocked(vAcc.locked);
    } catch (e) {
      console.error(`exception when update vault state: ${e}`);
    }
  }, [gb, vaultId]);

  const init = useCallback(() => {
    (async () => {
      if (wallet && connection) {
        //prep vault + bank variables
        await updateVaultState();

        //fetch farmer state
        await fetchFarmer();

        //populate wallet + vault nfts
        await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
      }
    })();
  }, [
    wallet,
    connection,
    fetchFarmer,
    populateVaultNFTs,
    populateWalletNFTs,
    updateVaultState,
  ]);

  // --------------------------------------- actions

  const beginStaking = async () => {
    if (isTimeExpired(stakingDeadline)) {
      enqueueSnackbar(`Can't stake after passing application deadline`, {
        variant: "error",
      });
      return;
    }

    if (jobFull) {
      enqueueSnackbar(`Can't stake when the market is full`, {
        variant: "error",
      });
      return;
    }

    setIsProcessing(true);
    try {
      let { txSig } = await gf.stakeWallet(farmId);
      await connection.confirmTransaction(txSig);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchFarmer();
      enqueueSnackbar(`Houses staked!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(`exception when staking: ${e}`);
      enqueueSnackbar(`Unable to stake: ${errorMessage(e)}`, {
        variant: "error",
      });
    }
    setIsProcessing(false);
  };

  const endStaking = async () => {
    setIsProcessing(true);
    try {
      let { txSig } = await gf.unstakeWallet(farmId);
      await connection.confirmTransaction(txSig);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchFarmer();
      enqueueSnackbar(`Houses unstaked!`, {
        variant: "success",
      });
    } catch (e) {
      console.error(`exception when unstaking: ${e}`);
      enqueueSnackbar(`Unable to unstake: ${errorMessage(e)}`, {
        variant: "error",
      });
    }
    setIsProcessing(false);
  };

  const depositGems = async () => {
    if (!selectedWalletNFTs || selectedWalletNFTs.length === 0) {
      return;
    }

    if (isTimeExpired(stakingDeadline)) {
      enqueueSnackbar(`Application deadline has passed!`, {
        variant: "error",
      });
      return;
    }

    if (jobFull) {
      enqueueSnackbar(`This market is full!`, {
        variant: "error",
      });
      return;
    }

    let signatures = [];
    setIsProcessing(true);
    let exception = false;

    for (const nft of selectedWalletNFTs) {
      console.log(nft);
      const creator = new PublicKey(
        //todo currently simply taking the 1st creator
        (nft.onchainMetadata as any).data.creators[0].address
      );
      console.log("creator is", creator.toBase58());
      try {
        const { txSig } = await gb.depositGemWallet(
          bank,
          vaultId,
          new BN(1),
          nft.mint,
          nft.pubkey!,
          creator
        );
        signatures.push(txSig);
        console.log("deposit done", txSig);
      } catch (e) {
        exception = true;
        console.error(`exception when depositing: ${e}`);
        enqueueSnackbar(`Unable to add houses: ${errorMessage(e)}`, {
          variant: "error",
        });
      }
    }

    const result = await Promise.all(
      signatures.map((s) => connection.confirmTransaction(s))
    );

    const failedTx = result.filter((r) => r.value.err != null);

    if (failedTx.length > 0) {
      console.error(`Transactions failed: ${failedTx}`);
      enqueueSnackbar(`Transactions failed: ${failedTx}`, {
        variant: "error",
      });
    } else if (!exception) {
      console.log("All transactions succeeded:", signatures);
      enqueueSnackbar(`Houses added!`, {
        variant: "success",
      });
    }

    setSelectedVaultNFTs([]);
    setSelectedWalletNFTs([]);
    setIndeterminateAvailable(false);
    setIndeterminateWorkforce(false);
    setCheckAllAvailable(false);
    setCheckAllWorkforce(false);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    setIsProcessing(false);
  };

  const withdrawGems = async () => {
    if (!selectedVaultNFTs || selectedVaultNFTs.length === 0) {
      return;
    }

    let signatures = [];
    setIsProcessing(true);
    let exception = false;

    for (const nft of selectedVaultNFTs) {
      try {
        const { txSig } = await gb.withdrawGemWallet(
          bank,
          vaultId,
          new BN(1),
          nft.mint
        );
        signatures.push(txSig);
        console.log("withdrawal done", txSig);
      } catch (e) {
        exception = true;
        console.error(`exception when withdrawing: ${e}`);
        enqueueSnackbar(`Unable to remove houses: ${errorMessage(e)}`, {
          variant: "error",
        });
      }
    }

    const result = await Promise.all(
      signatures.map((s) => connection.confirmTransaction(s))
    );

    const failedTx = result.filter((r) => r.value.err != null);

    if (failedTx.length > 0) {
      console.error(`Transactions failed: ${failedTx}`);
      enqueueSnackbar(`Transactions failed: ${failedTx}`, {
        variant: "error",
      });
    } else if (!exception) {
      console.log("All transactions succeeded:", signatures);
      enqueueSnackbar(`Houses removed as rentals`, {
        variant: "success",
      });
    }

    setSelectedVaultNFTs([]);
    setSelectedWalletNFTs([]);
    setIndeterminateAvailable(false);
    setIndeterminateWorkforce(false);
    setCheckAllAvailable(false);
    setCheckAllWorkforce(false);

    await new Promise((resolve) => setTimeout(resolve, 1000));
    await Promise.all([populateWalletNFTs(), populateVaultNFTs()]);
    setIsProcessing(false);
  };

  const onCheckAllAvailableChange = (e: CheckboxChangeEvent) => {
    setSelectedWalletNFTs(e.target.checked ? currentWalletNFTs : []);
    setIndeterminateAvailable(false);
    setCheckAllAvailable(e.target.checked);
  };

  const onCheckAllWorkforceChange = (e: CheckboxChangeEvent) => {
    setSelectedVaultNFTs(e.target.checked ? currentVaultNFTs : []);
    setIndeterminateWorkforce(false);
    setCheckAllWorkforce(e.target.checked);
  };

  useEffect(init, [wallet, connection, init]);

  return (
    <StakingPanelContainer>
      <StakingModalContainer
        title="Are you sure?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={[
          <ModalStakeButton key="submit" type="primary" onClick={handleOk}>
            Stake
          </ModalStakeButton>,
          //   <CancelButton key="back" onClick={handleCancel}>
          //     Cancel
          //   </CancelButton>,
        ]}
      >
        <p>
          You're staking in a market that has a lock-up period. You will{" "}
          <strong>NOT</strong> be able to remove or add any houses during the
          lock-up period.
        </p>
      </StakingModalContainer>
      <TitleContainer>
        <Titles>
          <AvailableTitle>Available</AvailableTitle>
          <RentalsTitle>Rentals</RentalsTitle>
        </Titles>
        <TitleDivider />
      </TitleContainer>
      <HouseContainer>
        <AvailableHouseContainer>
          {isLoadingWalletNFTs ? (
            <Spin size="large" />
          ) : currentWalletNFTs && currentWalletNFTs.length > 0 ? (
            <>
              <CheckAllContainer>
                <HouseCountTitle>{`${currentWalletNFTs.length} House${
                  currentWalletNFTs.length > 1 ? "s" : ""
                }`}</HouseCountTitle>
                <CheckboxTitle>
                  Select All{" "}
                  <Checkbox
                    indeterminate={indeterminateAvailable}
                    disabled={farmerState !== "unstaked"}
                    onChange={onCheckAllAvailableChange}
                    checked={checkAllAvailable}
                  ></Checkbox>
                </CheckboxTitle>
              </CheckAllContainer>
              <CheckboxGroup
                onChange={onAvailableHouseSelect}
                value={selectedWalletNFTs}
                disabled={farmerState !== "unstaked"}
              >
                <AvailableHouseRow>
                  {currentWalletNFTs.map((item, index) => (
                    <Col span={12}>
                      <SDCHouseStakeCard key={index} item={item} />
                    </Col>
                  ))}
                </AvailableHouseRow>
              </CheckboxGroup>
            </>
          ) : (
            <AvailableHouseContainer>
              <NoStakedHouseImg src="./rental/noavailableimg.png"></NoStakedHouseImg>
            </AvailableHouseContainer>
          )}
        </AvailableHouseContainer>

        <ActionButtons>
          <ActionButton
            onClick={depositGems}
            disabled={
              isProcessing ||
              farmerState !== "unstaked" ||
              selectedWalletNFTs === null ||
              selectedWalletNFTs.length === 0
            }
          >
            {isProcessing ? (
              <CircularProgress size={20} />
            ) : (
              <>
                Add <ArrowRightOutlined />
              </>
            )}
          </ActionButton>
          <ActionButton
            onClick={withdrawGems}
            disabled={
              isProcessing ||
              farmerState !== "unstaked" ||
              selectedVaultNFTs === null ||
              selectedVaultNFTs.length === 0
            }
          >
            {isProcessing ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <ArrowLeftOutlined /> Remove
              </>
            )}
          </ActionButton>
        </ActionButtons>

        <StakedHouseContainer>
          {isLoadingVaultNFTs ? (
            <Spin size="large" />
          ) : currentVaultNFTs && currentVaultNFTs.length > 0 ? (
            <>
              <CheckAllContainer>
                <HouseCountTitle>{`${currentVaultNFTs.length} House${
                  currentVaultNFTs.length > 1 ? "s" : ""
                }`}</HouseCountTitle>
                <CheckboxTitle>
                  Select All{" "}
                  <Checkbox
                    indeterminate={indeterminateWorkforce}
                    disabled={farmerState !== "unstaked"}
                    onChange={onCheckAllWorkforceChange}
                    checked={checkAllWorkforce}
                  ></Checkbox>
                </CheckboxTitle>
              </CheckAllContainer>
              <Checkbox.Group
                onChange={onStakedHouseSelect}
                value={selectedVaultNFTs}
                disabled={farmerState !== "unstaked"}
              >
                <StakedHouseRow>
                  {currentVaultNFTs.map((item, index) => (
                    <Col span={12}>
                      <SDCHouseStakeCard key={index} item={item} />
                    </Col>
                  ))}
                </StakedHouseRow>
              </Checkbox.Group>

              <StartStakingContainer>
                <StartStakingTextContainer>
                  <StartStakingTextTitle>
                    {farmerState === "unstaked" &&
                      `Don't forget start staking all your house${
                        currentVaultNFTs.length > 1 ? "s" : ""
                      } as rentals.
                            `}
                    {farmerState === "staked" &&
                      !isTimeExpired(farmerLockupExpiring, refreshSeed) && (
                        <TimeToLockTimerStats>
                          <StartStakingText>Time to Unlock:</StartStakingText>
                          <Countdown
                            autoStart={true}
                            date={farmerLockupExpiring}
                            renderer={({
                              days,
                              hours,
                              minutes,
                              seconds,
                              completed,
                            }: CountdownRenderProps) => {
                              if (completed) {
                                setRefreshSeed(Math.random());
                                // Render a completed state
                                return "";
                              } else {
                                // Render a countdown
                                return (
                                  <TimerText>{`${days}D ${hours}H ${minutes}M ${seconds}`}</TimerText>
                                );
                              }
                            }}
                          />
                        </TimeToLockTimerStats>
                      )}
                    {farmerState === "staked" &&
                      isTimeExpired(farmerLockupExpiring, refreshSeed) &&
                      `
                            Your houses are eligible for unstaking now.
                            `}
                  </StartStakingTextTitle>

                  {farmerState !== "staked" && (
                    <StartStakingText>
                      {isTimeExpired(stakingDeadline)
                        ? `Application deadline has passed!`
                        : jobFull
                        ? `This market is full!`
                        : lockupPeriod && lockupPeriod !== "None"
                        ? `Your houses will be locked up for ${lockupPeriod}. Please keep in mind you cannot remove nor add any houses during the lock
                                up period!`
                        : ""}
                    </StartStakingText>
                  )}
                  {farmerState === "staked" &&
                    isTimeExpired(farmerLockupExpiring, refreshSeed) && (
                      <StakingFinishedText>
                        {`Upon unstaking, you can remove or add houses to this market and start another staking period.`}
                      </StakingFinishedText>
                    )}

                  <StakeButtonWrapper>
                    {farmerState === "unstaked" && (
                      <StakeButton
                        disabled={
                          isProcessing ||
                          !currentVaultNFTs ||
                          currentVaultNFTs.length === 0 ||
                          isTimeExpired(stakingDeadline) ||
                          jobFull
                        }
                        onClick={
                          lockupPeriod && lockupPeriod !== "None"
                            ? showConfirmModal
                            : beginStaking
                        }
                      >
                        {isProcessing ? (
                          <CircularProgress size={20} />
                        ) : (
                          `Stake`
                        )}
                      </StakeButton>
                    )}
                    {farmerState === "staked" && (
                      <UnStakeButton
                        disabled={
                          isProcessing ||
                          !isTimeExpired(farmerLockupExpiring, refreshSeed)
                        }
                        onClick={endStaking}
                      >
                        {isProcessing ? (
                          <CircularProgress size={20} />
                        ) : (
                          `Unstake`
                        )}
                      </UnStakeButton>
                    )}
                  </StakeButtonWrapper>
                </StartStakingTextContainer>
              </StartStakingContainer>
            </>
          ) : (
            <StartStakingContainer>
              <NoStakedHouseImg src="./rental/nostakeimg.png"></NoStakedHouseImg>
            </StartStakingContainer>
          )}
        </StakedHouseContainer>
      </HouseContainer>
    </StakingPanelContainer>
  );
};

export default RentStakingPanel;
