import { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Row, Col, Collapse, Button } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import Countdown, { CountdownRenderProps, CountdownApi } from 'react-countdown'
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react"
import { PublicKey } from '@solana/web3.js';
import { stringifyPKsAndBNs, findFarmerPDA } from '../../sdk'
import moment from 'moment/moment';
import { useSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';
import { TOKEN_DECIMALS, errorMessage } from '../../global'

const { Panel } = Collapse

const JobWrapperPanel = styled(Panel)`
    width: 100%;
    background: rgba(24, 160, 251, 0.1);
    border-radius: 8px;
`

const JobWrapper = styled(Row)`
    padding: 0;
    height: 48px;
    width: 100%;
`

const JobImage = styled.img`
    width: 48px;
`
const JobTitlesWrapper = styled(Col)`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
`

const JobTitle = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    letter-spacing: -0.015em;
    color: #30383D;
    @media (max-width: 1900px){
        font-size: 16px;
    }
`

const JobSubTitle = styled(JobTitle)`
    font-weight: normal;
    font-size: 12px;
    @media (max-width: 1900px){
        font-size: 10px;
    }
`

const JobInfo = styled(Col)`
    display: flex;
    flex-flow: column;
    justify-content: center;
`
const JobInfoTitle = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #40667A;
`

const JobInfoStats = styled.div`
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #40667A;
`

const ExpandIcon = styled(Col)`
    display: flex;
    margin-left: auto;
    flex-flow: column;
    justify-content: center;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #18A0FB;
`

const ExpandedContext = styled(Row)`
    width: 100%;
    height: 104px;
    background: rgba(24, 160, 251, 0.3);
`

const StakeButtonWrapper = styled(Col)`
    display: flex;
    flex-flow: column;
    justify-content: center;
`
const StakeButton = styled(Button)`
    margin: 27px 0 27px 12px;
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
    height: 36px;
    background-blend-mode: soft-light, normal;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 11.25px;
    border: none;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: 400;
    font-size: 18.51px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 3%;
    color: #eeebeb;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    &:hover {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #7438f5;
        color: #eeebeb;
    }
    &:active, &:focus {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
        color: #eeebeb;
    }
`

const StakeWrapper = styled(Col)`
    display: flex;
    flex-flow: row;
`

const TimeToLockTimerWrapper = styled(Col)`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
`

const TimeToLockTimerTitle = styled.div`
    justify-content: center;
    margin-bottom: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #18A0FB;
`

const TimeToLockTimerStats = styled(Col)`
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #18A0FB;

`

const SoulDogsStakedWrapper = styled(Col)`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
`

const SoulDogsBoxWrapper = styled.div`
    height: 62px;
    background: rgba(24, 160, 251, 0.2);
    border-radius: 8px 0px 0px 8px;
    padding: 16px 19px;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 1px;
`

const SoulDogsStakedTitle = styled.div`
    justify-content: center;
    margin-bottom: 1px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
`

const SoulDogsStakedStats = styled.div`
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
`

const BonesEarnedWrapper = styled(Col)`
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
`

const BonesEarnedBoxWrapper = styled.div`
    height: 62px;
    background: rgba(24, 160, 251, 0.2);
    border-radius: 0px 8px 8px 0px;
    padding: 16px 19px;
    margin-top: 15px;
    margin-bottom: 15px;
`

const BonesEarnedTitle = styled.div`
    justify-content: center;
    margin-bottom: 1px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
`

const BonesEarnedStats = styled.div`
    justify-content: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #000000;
`
const ClaimButtonWrapper = styled(Col)`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
`
const ClaimButton = styled(Button)`
    margin-bottom: 8px;
    width: 140px;
    background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
    background-blend-mode: soft-light, normal;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-radius: 11.25px;
    border: none;
    font-family: Luckiest Guy;
    font-style: normal;
    font-weight: 400;
    font-size: 18.51px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 3%;
    color: #eeebeb;
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    &:hover {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #7438f5;
        color: #eeebeb;
    }
    &:focus {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
        color: #eeebeb;   
    }
    &:active {
        background: linear-gradient(311.99deg, rgba(0, 0, 0, 0.5) -22.55%, rgba(255, 255, 255, 0.5) 131.34%), #6311FF;
        color: #eeebeb;
    }
`

const RefreshButton = styled(ClaimButton)`
    margin-right: 0;
`
const SecondsInADay = 86400;

const truncatedNumberStr = (n: number) => {
    return parseFloat((n).toFixed(2)).toString();
}

interface SDCJobInterface {
    key: string;
    gf: any; // gemFarm object
    item: {
        farmId: string;
        img: string,
        title: string,
        subTitle?: string,
    },
    showJobBoardStaking: (farmId: string, vaultId: string, lockupPeriod: string, stakeDeadline: number, farmFull: boolean) => void;
    setFarmTotal: (farmId: string, total: number) => void;
    filtered?: boolean;
}

interface SDCJobTimerInterface {
    lockupExpiring: number;
    rewardsEndTime: number;
    denominator: number;
    beginStakingTime: number;
    registered: any;
    isRegistering: boolean;
    jobFull: boolean;
    deadline: number;
    showJobBoardStaking?: () => void;
    registerStaker?: () => void;
}


const StakeTimer = ({ lockupExpiring, rewardsEndTime, denominator, beginStakingTime, registered, jobFull, isRegistering, deadline, showJobBoardStaking, registerStaker }: SDCJobTimerInterface) => {
    const [showTimer, setShowTimer] = useState(true);
    const [timerEndsAt, setTimerEndsAt] = useState(0);
    const [state, setState] = useState('none');

    useEffect(() => {

        console.log('refresh');

        const currentTime = Date.now();
        if (denominator <= 60000 || lockupExpiring <= 0) {
            // no need for timer
            setState('none');
            setTimerEndsAt(0);
            console.log('none');
        }
        else if (currentTime <= lockupExpiring) {
            setState('lockup');
            setTimerEndsAt(lockupExpiring);
            console.log(`lock up ${lockupExpiring}`);
        }
        else if (currentTime > lockupExpiring && currentTime <= rewardsEndTime) {
            const passedPeriod = Math.floor((currentTime - beginStakingTime) / denominator);
            const nextPayDate = beginStakingTime + (passedPeriod + 1) * denominator;
            if (nextPayDate > rewardsEndTime) {
                setState('end');
                setTimerEndsAt(0);
                console.log(`end`);
            }
            else {
                setState('paid');
                setTimerEndsAt(nextPayDate);
                console.log(`paid ${nextPayDate}`);
            }
        }

        if (currentTime > rewardsEndTime) {
            setState('end');
            setTimerEndsAt(0);
            console.log(`end`);
        }

        setShowTimer(true);
    }, [showTimer, lockupExpiring, rewardsEndTime, denominator, beginStakingTime]);

    return (
        <StakeWrapper span={8} >
            <StakeButtonWrapper >
                {registered &&
                    (<StakeButton onClick={showJobBoardStaking}>
                        {`VIEW`}
                    </StakeButton>)}
                {!registered &&
                    (<StakeButton disabled={isRegistering || jobFull || Date.now() > deadline} onClick={registerStaker}>
                        {isRegistering ? <CircularProgress size={20} />
                            : (jobFull ? `FULL`
                                : (Date.now() > deadline ? `CLOSED` :
                                    `APPLY`))}
                    </StakeButton>)}

            </StakeButtonWrapper>
            {showTimer && registered && registered?.gemsStaked?.toNumber() > 0 && state !== 'none' && <TimeToLockTimerWrapper>
                <TimeToLockTimerTitle>
                    {state === 'lockup' ? `Time To Unlock` :
                        state === 'paid' ? `Time To Next Pay` :
                            `Job Ended, Unstake Now!`}
                </TimeToLockTimerTitle>
                {state !== "end" && <TimeToLockTimerStats>
                    <Countdown autoStart={true} date={timerEndsAt} renderer={({ days, hours, minutes, seconds, completed }: CountdownRenderProps) => {
                        if (completed) {
                            // refresh when ends
                            setShowTimer(false);
                            return ""
                        } else {
                            // Render a countdown
                            return <span>{`${days}D ${hours}H ${minutes}M ${seconds}`}</span>;
                        }
                    }} />
                </TimeToLockTimerStats>}
            </TimeToLockTimerWrapper>
            }
        </StakeWrapper>
    )
}

const SDCJob = ({ key, item, gf, showJobBoardStaking, setFarmTotal, filtered }: SDCJobInterface) => {
    const [expanded, setExpanded] = useState(false);

    const wallet = useAnchorWallet();
    const connection = useConnection().connection;
    const { enqueueSnackbar } = useSnackbar();

    // --------------------------------------- button states
    const [isClaiming, setIsClaiming] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isRegistering, setIsRegistering] = useState(false);

    // --------------------------------------- farm & farmer details
    const [farmAcc, setFarmAcc] = useState<any>(null);
    const [farmerAcc, setFarmerAcc] = useState<any>(null);
    const [farmerState, setFarmerState] = useState<string | null>(null);
    const [farmLockupPeriod, setFarmLockupPeriod] = useState<string | null>(null);
    const [farmStakedTotal, setFarmStakedTotal] = useState<number>(0);
    const [stakeDeadline, setStakeDeadline] = useState<number>(Number.MAX_SAFE_INTEGER);
    const [dailyRewards, setDailyRewards] = useState<string | null>(null);
    const [farmerLockupExpiring, setFarmerLockupExpiring] = useState<number>(0);
    const [farmerStakedTotal, setFarmerStakedTotal] = useState<number>(0);
    const [availableReward, setAvailableReward] = useState<number>(0);
    const [maxPositions, setMaxPositions] = useState<number>(0);
    const [denominator, setDenominator] = useState<number>(0);
    const [rewardsEnd, setRewardsEnd] = useState<number>(0);
    const [beginStakingTime, setbeginStakingTime] = useState<number>(0);

    const fetchFarm = useCallback(async () => {
        const fetchedFarmAcc = await gf.fetchFarmAcc(new PublicKey(item.farmId));
        setFarmAcc(fetchedFarmAcc);

        const farmLockupInSecs = fetchedFarmAcc?.config?.minStakingPeriodSec?.toNumber();
        const dayStr = truncatedNumberStr(farmLockupInSecs / SecondsInADay);
        setFarmLockupPeriod(farmLockupInSecs ? dayStr + (dayStr === "1" ? " Day" : " Days") : "None");

        setFarmStakedTotal(fetchedFarmAcc?.gemsStaked?.toNumber());
        setFarmTotal(item.farmId, fetchedFarmAcc?.gemsStaked?.toNumber());
        setMaxPositions(fetchedFarmAcc?.maxCounts?.maxGems);

        const baseRewardsRate = fetchedFarmAcc?.rewardA?.fixedRate?.schedule?.baseRate.toNumber();
        const denominator = fetchedFarmAcc?.rewardA?.fixedRate?.schedule?.denominator.toNumber();
        setDenominator(denominator * 1000);

        const rewardsPerDay = baseRewardsRate * SecondsInADay / denominator;
        setDailyRewards(truncatedNumberStr(rewardsPerDay / (10 ** TOKEN_DECIMALS)) + " $BONES");

        // stake deadline = duration - denominator
        if (fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber()) {
            setRewardsEnd(fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber() * 1000);
            setStakeDeadline((fetchedFarmAcc?.rewardA?.times?.rewardEndTs?.toNumber() - denominator) * 1000);
        }
        else {
            setRewardsEnd(0);
            setStakeDeadline(Number.MAX_SAFE_INTEGER);
        }

        console.log(
            `farm found at ${item.farmId}:`,
            stringifyPKsAndBNs(fetchedFarmAcc)
        );
    }, [gf, item.farmId]);

    const fetchFarmer = useCallback(async () => {
        const [farmerPDA] = await findFarmerPDA(
            new PublicKey(item.farmId),
            wallet!.publicKey
        );

        const fetchedFarmerAcc = await gf.fetchFarmerAcc(farmerPDA);
        setFarmerAcc(fetchedFarmerAcc);
        setFarmerState(gf.parseFarmerState(fetchedFarmerAcc));
        setbeginStakingTime(fetchedFarmerAcc?.rewardA?.fixedRate?.beginStakingTs * 1000);

        setAvailableReward(fetchedFarmerAcc?.rewardA?.accruedReward
            .sub(fetchedFarmerAcc?.rewardA?.paidOutReward)
            .toNumber());

        setFarmerStakedTotal(fetchedFarmerAcc?.gemsStaked?.toNumber());
        setFarmerLockupExpiring(fetchedFarmerAcc?.minStakingEndsTs?.toNumber() * 1000);

        console.log(
            `farmer found at ${wallet!.publicKey?.toBase58()}:`,
            stringifyPKsAndBNs(fetchedFarmerAcc)
        );
    }, [gf, item.farmId, wallet]);

    const initFarmer = async () => {
        setIsRegistering(true);
        try {
            let { txSig } = await gf.initFarmerWallet(new PublicKey(item.farmId));
            await connection.confirmTransaction(txSig);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await fetchFarmer();
            enqueueSnackbar(`Application success!`, {
                variant: "success",
            });
        }
        catch (e) {
            console.error(`exception when applying: ${e}`);
            enqueueSnackbar(`Unable to apply: ${errorMessage(e)}`, {
                variant: "error",
            });
        }
        setIsRegistering(false);
    };

    const refreshStates = () => {
        (async () => {
            if (wallet && connection && gf) {
                //reset stuff
                setFarmerAcc(null);
                setFarmerState(null);
                setAvailableReward(0);

                try {
                    await fetchFarm();
                    await fetchFarmer();
                } catch (e) {
                    console.error(`exception when fetching farm&farmer data ${item.farmId}: ${e}`);
                }
            }
        })();
    };

    const claim = async () => {

        if (!farmAcc) {
            return;
        }

        setIsClaiming(true);
        try {
            let { txSig } = await gf.claimWallet(
                new PublicKey(item.farmId),
                farmAcc.rewardA.rewardMint!,
                farmAcc.rewardA.rewardMint!,
            );

            await connection.confirmTransaction(txSig);
            await fetchFarmer();

            enqueueSnackbar(`BONES claimed!`, {
                variant: "success",
            });
        }
        catch (e) {
            console.error(`exception when claiming: ${e}`);
            enqueueSnackbar(`Unable to claim tokens: ${errorMessage(e)}`, {
                variant: "error",
            });
        }
        setIsClaiming(false);
    };

    useEffect(refreshStates, [gf, connection, wallet, item.farmId, fetchFarm, fetchFarmer]);

    const refreshFarmer = async () => {
        setIsRefreshing(true);
        try {
            let { txSig } = await gf.refreshFarmerWallet(
                new PublicKey(item.farmId),
                wallet?.publicKey,
            );

            await connection.confirmTransaction(txSig);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await fetchFarmer();

            enqueueSnackbar(`Earning refreshed!`, {
                variant: "success",
            });
        }
        catch (e) {
            console.error(`exception when refresh farmer: ${e}`);
            enqueueSnackbar(`Unable to refresh earning: ${errorMessage(e)}`, {
                variant: "error",
            });
        }
        setIsRefreshing(false);
    }

    return (
        <>
            {!filtered && <Collapse style={{ borderRadius: '8px' }} bordered={false} onChange={() => setExpanded(!expanded)}>
                <JobWrapperPanel showArrow={false} header={
                    <JobWrapper>
                        <Col span={2}>
                            <JobImage src={item.img}></JobImage>
                        </Col>
                        <JobTitlesWrapper span={5}>
                            <JobTitle>{item.title}</JobTitle>
                            {item.subTitle && <JobSubTitle>{item.subTitle}</JobSubTitle>}
                        </JobTitlesWrapper>
                        <JobInfo span={3}>
                            <JobInfoTitle>Open Positions</JobInfoTitle>
                            <JobInfoStats>{maxPositions ? (maxPositions - farmStakedTotal) + " / " + maxPositions : "Unlimited"}</JobInfoStats>
                        </JobInfo>
                        <JobInfo span={4}>
                            <JobInfoTitle>Daily Salary</JobInfoTitle>
                            <JobInfoStats>{dailyRewards}</JobInfoStats>
                        </JobInfo>
                        <JobInfo span={3}>
                            <JobInfoTitle>Lock Up</JobInfoTitle>
                            <JobInfoStats>{farmLockupPeriod}</JobInfoStats>
                        </JobInfo>
                        <JobInfo span={6}>
                            <JobInfoTitle>Application Deadline</JobInfoTitle>
                            <JobInfoStats>{stakeDeadline !== Number.MAX_SAFE_INTEGER ? moment(new Date(stakeDeadline)).format('MMM Do YY, h:mm a') : "None"}</JobInfoStats>
                        </JobInfo>
                        {expanded ? <ExpandIcon><UpOutlined /></ExpandIcon> : <ExpandIcon><DownOutlined /></ExpandIcon>}
                    </JobWrapper>
                }
                    key={key}>
                    <ExpandedContext>
                        <StakeTimer
                            lockupExpiring={farmerLockupExpiring}
                            rewardsEndTime={rewardsEnd}
                            denominator={denominator}
                            beginStakingTime={beginStakingTime}
                            registered={farmerAcc}
                            isRegistering={isRegistering}
                            jobFull={maxPositions !== 0 && farmStakedTotal >= maxPositions}
                            deadline={stakeDeadline}
                            showJobBoardStaking={() => {
                                showJobBoardStaking(item.farmId, farmerAcc?.vault?.toBase58(),
                                    farmLockupPeriod ?? "None",
                                    stakeDeadline,
                                    maxPositions !== 0 && farmStakedTotal >= maxPositions)
                            }}
                            registerStaker={initFarmer} />
                        <SoulDogsStakedWrapper span={4}>
                            <SoulDogsBoxWrapper>
                                <SoulDogsStakedTitle>
                                    {`Soul Dogs Staked`}
                                </SoulDogsStakedTitle>
                                <SoulDogsStakedStats>
                                    {farmerStakedTotal}
                                </SoulDogsStakedStats>
                            </SoulDogsBoxWrapper>
                        </SoulDogsStakedWrapper>
                        <BonesEarnedWrapper span={4}>
                            <BonesEarnedBoxWrapper>
                                <BonesEarnedTitle>
                                    {`BONES Earned`}
                                </BonesEarnedTitle>
                                <BonesEarnedStats>
                                    {availableReward / (10 ** TOKEN_DECIMALS)}
                                </BonesEarnedStats>
                            </BonesEarnedBoxWrapper>
                        </BonesEarnedWrapper>
                        <ClaimButtonWrapper span={6}>
                            <ClaimButton disabled={!farmerAcc || availableReward <= 0 || isClaiming} onClick={claim}>
                                {isClaiming ? <CircularProgress size={20} /> : `Claim`}
                            </ClaimButton>
                            <RefreshButton disabled={!farmerAcc || isRefreshing} onClick={refreshFarmer}>
                                {isRefreshing ? <CircularProgress size={20} /> : `Timecard`}
                            </RefreshButton>
                        </ClaimButtonWrapper>
                    </ExpandedContext>
                </JobWrapperPanel>
            </Collapse>
            }
        </>
    )
}

export default SDCJob